import { useCallback, useState } from 'react'
import { useGetLocationSuggest } from '@genoa/middle-end'

import { useLogger } from '../.././../../../providers'
import { PortalAddress } from './types'

export const useLocationSuggest = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const loggerV2 = useLogger('use-location-suggest - portalAddressEntryContainer')

  const [, getLocationSuggest] = useGetLocationSuggest()

  const submit = useCallback(
    async (address_line_1: string, address_line_2: string, city: string, state: string, zip: string) => {
      try {
        setIsSubmitting(true)
        const response = await getLocationSuggest({
          address_line_1,
          address_line_2,
          city,
          state,
          zip,
        })
        if (response.status === 200 && response?.data?.skip_confirmation) {
          return
        } else if (response.status === 200) {
          return response.data.address as PortalAddress
        } else if (response.status === 429) {
          loggerV2.error('useLocationSuggest', 'Rate limit status code: 429')
        } else {
          loggerV2.error('useLocationSuggest', `Non 200 status-code: ${response.status}`)
        }
        return
      } catch (error: any) {
        loggerV2.error('useLocationSuggest', `Unhandled error: ${error?.message}`)
      } finally {
        setIsSubmitting(false)
      }
    },
    [getLocationSuggest]
  )

  return {
    isSubmitting,
    submit,
  }
}
