import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { Theme } from '@emotion/react'
import styled from '@emotion/styled'

type NoneOfTheAboveProps = {
  onPress: () => unknown
  theme: Theme
  cursor?: string
}

export const NoneOfTheAbove = (props: NoneOfTheAboveProps) => (
  <Box
    key="none-of-above-container"
    onClick={props.onPress}
    data-testid="DirectIntegrationUnitSelectionNoneOfTheAboveButton"
  >
    <Flex flex={1} flexDirection="row" alignItems="center" justifyContent="center">
      <Box minH="24px" />
      <StyledSmallText
        data-testid="DirectIntegrationUnitSelectionNoneOfTheAboveText"
        color={props.theme.colors.brand[900]}
        cursor="pointer"
      >
        None of the above
      </StyledSmallText>
    </Flex>
  </Box>
)

const StyledSmallText = styled(Flex)`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 18px;
`
