import React from 'react'
import { OnboardingStepStatus } from '@genoa/middle-end'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { OnboardingStepMapping } from '../../../../hooks/flex2/onboarding-status'
import { HeroLaptop as ResumeImage } from '../../../assets'
import { Headline1, PrimaryButton, Text } from '../../../components'
import { BasePageLayout } from '../../../layouts'
import { ResumeOnboardingStep } from './ResumeOnboardingStep'

type ResumeOnboardingToStepProps = {
  customerName: string
  onResume: () => void
  onStepClicked: (step: string) => void
  onboardingSteps: OnboardingStepMapping[]
  loading?: boolean
  header: string
  body: string
  cta: string
}

export const ResumeOnboardingToStep = (props: ResumeOnboardingToStepProps) => (
  <BasePageLayout>
    <Box minH="15px" />

    <Flex flexGrow={1} flexShrink={0} flexDirection="column" data-testid="ResumeOnboardingPage">
      <Flex align="center" justify="center">
        <ResumeOnboardingImage />
      </Flex>

      <Box minH="5px" />

      <Headline1 textAlign="center">{props.header}</Headline1>

      <Box minH="16px" />

      <Flex align="center" justify="center">
        <ExtendedSubHeader textAlign="center">{props.body}</ExtendedSubHeader>
      </Flex>

      <Box minH="32px" />

      <Flex direction="column">
        {props.onboardingSteps.map((step) => {
          return (
            <ResumeOnboardingStep
              key={step.step}
              stepName={step.name}
              isCompleted={step.status === OnboardingStepStatus.COMPLETE}
              isCurrentStep={step.isCurrentStep}
              onStepClicked={props.onStepClicked}
            />
          )
        })}
      </Flex>

      <Box minH="20px" />
      <PrimaryButton
        onClick={props.onResume}
        processing={props.loading}
        disabled={props.loading}
        testID="ResumeOnboardingContinueButton"
      >
        {props.cta}
      </PrimaryButton>
    </Flex>

    <Box minH="30px" />
  </BasePageLayout>
)

const ResumeOnboardingImage = styled(ResumeImage)`
  width: 235px;
  height: 230px;
`

const ExtendedSubHeader = styled(Text)`
  margin: -1px;
`
