import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { actionLogToSelectedEvent, SetupAutopilotAction } from '@genoa/domain'

import { useAuthState } from '../../../../contexts'
import { useAutopilotEligibility } from '../../../../hooks'
import { useAutopilotSetupMutation } from '../../../../modules/flexApi'
import { logger, useAnalytics } from '../../../../providers'
import { Gap } from '../../../components'
import { BasePageLayout } from '../../../layouts'
import { AutopaySetup } from './AutopaySetup'

export interface AutopaySetupContainerProps {
  analyticsScreenName: Analytics.Screens
}

export const AutopaySetupContainer = (props: AutopaySetupContainerProps) => {
  const { uid } = useAuthState()
  const analytics = useAnalytics()
  const location = useLocation()
  const navigate = useNavigate()
  const { isUserAutopayEnabled } = useAutopilotEligibility()
  const [selectedOption, setSelectedOption] = useState<SetupAutopilotAction | undefined>(undefined)
  const [autopilotSetup, { isLoading }] = useAutopilotSetupMutation()

  useEffect(() => {
    analytics.logScreenView(props.analyticsScreenName)
  }, [])

  useEffect(() => {
    setSelectedOption(isUserAutopayEnabled ? SetupAutopilotAction.AUTOPAY_ON : SetupAutopilotAction.EPISODIC_ON)
  }, [isUserAutopayEnabled])

  const onSelectOption = (option: SetupAutopilotAction) => {
    setSelectedOption(option)
    return analytics.logEvent(actionLogToSelectedEvent[option])
  }

  const onConfirm = async () => {
    if (!selectedOption) {
      return undefined
    }

    analytics.logEvent(Analytics.Events.AUTOPAY_SETUP_SAVED, {
      autopay: selectedOption === SetupAutopilotAction.AUTOPAY_ON ? 'on' : 'off',
    })

    try {
      await autopilotSetup({ customerId: uid!, status: selectedOption })
    } catch (error) {
      logger.error('AutopaySetupContainer', 'Error setting up Autopay', (error as Error).stack)
    }

    return navigate(location.state.nextRoute)
  }

  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Gap size="spacing_300" />
      <AutopaySetup
        confirmDisabled={isLoading}
        selectedOption={selectedOption}
        onSelectOption={onSelectOption}
        onConfirm={onConfirm}
      />
    </BasePageLayout>
  )
}
