import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { AmplitudeFeatureFlag } from '@genoa/experiments'
import { GeneralApiErrorCodes, GetPaymentScheduleResponse, useQueryError } from '@genoa/state-management'

import { useAuthState } from '../../../../../../contexts'
import { usePaymentStatus, usePaymentStatusToSchedule, useSecureLineOfCredit } from '../../../../../../hooks'
import { useAmplitudeFeatureFlag } from '../../../../../../hooks/use-amplitude-feature-flag'
import { useCustomerStatusState } from '../../../../../../modules/flex2/use-state'
import { useBillPayNowMutation, useLazyGetPaymentScheduleQuery } from '../../../../../../modules/flexApi'
import { useAnalytics } from '../../../../../../providers'
import * as Routes from '../../../../../../routing/constants'
import { PaymentScheduleItemProps } from '../../../../../components/PaymentSchedule/PaymentScheduleItem'
import { useCurrentGetCustomerStatus } from './use-current-customer-status'
import { useEstimatedPaymentItems } from './use-estimated-payment-items'

export const useGetPayments = () => {
  const analytics = useAnalytics()
  const navigate = useNavigate()
  const { uid } = useAuthState()
  const {
    customerStatus: { actual_amount: actualAmount },
  } = useCustomerStatusState()

  const { isEnabledForSLC, slcType } = useSecureLineOfCredit()
  const { isCustomerStatusReady } = useCurrentGetCustomerStatus()
  const { enabled: midFebRefactorEnabled } = useAmplitudeFeatureFlag(AmplitudeFeatureFlag.PaymentStatusRefactorMidFeb)

  const [
    billPayNow,
    {
      isLoading: isBillPayNowLoading,
      isSuccess: isBillPayNowSuccess,
      error: billPayNowError,
      data: billPayNowResponse,
    },
  ] = useBillPayNowMutation()

  const [paymentScheduleData, setPaymentScheduleData] = useState<GetPaymentScheduleResponse>()
  const [getPaymentSchedule, { isLoading: isGetPaymentScheduleLoading, error: paymentScheduleError }] =
    useLazyGetPaymentScheduleQuery()

  const paymentsV1 = useEstimatedPaymentItems(paymentScheduleData?.data.payments)

  const {
    deposit,
    downPayment,
    repayments,
    isLoading: isPaymentStatusLoading,
    isFetching: isPaymentStatusFetching,
    error: paymentStatusError,
    refetch: getPaymentStatus,
  } = usePaymentStatus()

  const paymentsV2 = usePaymentStatusToSchedule(deposit || downPayment, repayments)

  useEffect(() => {
    if (isBillPayNowSuccess && (slcType || midFebRefactorEnabled)) {
      getPaymentStatus()
    }
  }, [billPayNowResponse, isBillPayNowSuccess])

  useEffect(() => {
    if (isBillPayNowSuccess) {
      const amountCent = billPayNowResponse?.data?.amount_cent!

      if (!isEnabledForSLC && !midFebRefactorEnabled) {
        const runPaymentsQuery = async () => {
          const paymentScheduleData = await getPaymentSchedule({
            customerId: uid!,
            override_estimated_bill_amount_cent: amountCent,
          }).unwrap()

          setPaymentScheduleData(paymentScheduleData)
        }
        runPaymentsQuery()
      }
    }
  }, [billPayNowResponse, isBillPayNowSuccess])

  useEffect(() => {
    const submitBillPayNow = async () => {
      await billPayNow({ customerId: uid!, amount_cent: actualAmount, source: 'EMBED' })
    }
    if (isCustomerStatusReady) {
      submitBillPayNow()
    }
  }, [isCustomerStatusReady])

  useQueryError(billPayNowError || paymentStatusError || paymentScheduleError, {
    onFlexApiError({ data: { error } }) {
      if (error.code === GeneralApiErrorCodes.RATE_LIMIT) {
        navigate(Routes.Embed.CONGRATS, { state: { errorCode: GeneralApiErrorCodes.RATE_LIMIT } })
        return true
      }
    },
    onAllErrors() {
      analytics.logEvent(Analytics.Events.MANUAL_PAY_CONFIRM_AMOUNT_CTA_ERROR)
      navigate(Routes.Embed.CONGRATS, { state: { errorCode: 'default' } })
    },
  })

  const payments = isEnabledForSLC || midFebRefactorEnabled ? paymentsV2 : paymentsV1

  const isStartPayLoading =
    isBillPayNowLoading || isGetPaymentScheduleLoading || isPaymentStatusLoading || isPaymentStatusFetching

  const amountCent = billPayNowResponse?.data?.amount_cent!
  const payTo = billPayNowResponse?.data?.pay_to!

  const downPaymentAmount = useMemo(
    () =>
      payments?.find(
        (payment: PaymentScheduleItemProps) =>
          payment.label === 'Deposit' || payment.label === '1st payment' || payment.label === 'When rent is due'
      )?.amount || '',
    [payments]
  )

  const secondPayment = useMemo(
    () =>
      payments?.find(
        (payment: PaymentScheduleItemProps) => payment.label === '2nd payment' || payment.label === 'Payment'
      ),
    [payments]
  )

  const isSimplifiedEpisodicReady =
    isCustomerStatusReady && !!billPayNowResponse && !!payments?.length && !isStartPayLoading

  return {
    amountCent,
    downPaymentAmount,
    isSimplifiedEpisodicReady,
    isStartPayLoading,
    payments,
    payTo,
    secondPayment,
  }
}
