import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { Headline1, SmallText } from '../../components'
import { BasePageLayout } from '../../layouts'

type NotFoundProps = {}

export const NotFound = (props: NotFoundProps) => {
  const theme = useTheme()

  return (
    <BasePageLayout>
      <Box minH="80px" />

      <Flex flex={1} flexDirection="column" px="lg">
        <Flex align="center" justify="center">
          <JumboText color={theme.colors.brand[900]} textAlign="center">
            4<JumboText color={theme.colors.grey}>0</JumboText>4
          </JumboText>
        </Flex>

        <Box minH="80px" />

        <Headline1 textAlign="center">Page Not Found</Headline1>

        <Box minH="15px" />

        <SmallText textAlign="center">The page you are looking for doesn&apos;t exist :(</SmallText>
      </Flex>
    </BasePageLayout>
  )
}

const JumboText = styled(Headline1)`
  font-size: 80px;
  display: inline;
`
