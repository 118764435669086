import { Analytics } from '@genoa/analytics'
import { CONFIRM_CARD } from '@genoa/screen-content'
import { capitalizeFirstLetter } from '@genoa/utils'
import { Box, Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { ExtraSmallText, Headline1, InlineButton, PrimaryButton, SmallText } from '../../../../../components'
import { BasePageLayout } from '../../../../../layouts'

type ConfirmCardProps = {
  analyticsScreenName: Analytics.Screens
  onLinkDifferentCard: () => unknown
  onPressConfirm: () => unknown
  cardLast4Digits: string
  cardNetwork: string
  cardType: string
  isLoading?: boolean
}

export const ConfirmCard = (props: ConfirmCardProps) => {
  const theme = useTheme()

  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Box minH="30px" />

      <Headline1>{CONFIRM_CARD.HEADER}</Headline1>

      <Box minH="24px" />

      <SmallText data-testid="ConfirmCardBody">{CONFIRM_CARD.BODY}</SmallText>

      <Box minH="32px" />

      <ConfirmCardDetail flexDirection="column">
        <SmallText fontSize={16}>
          {capitalizeFirstLetter(props.cardType)} card ending in {props.cardLast4Digits}
        </SmallText>
        <Box minH="2px" />
        <SmallText color={theme.colors.grey}>{capitalizeFirstLetter(props.cardNetwork)}</SmallText>
      </ConfirmCardDetail>

      <Box minH="24px" />

      <Flex justifyContent="center">
        <InlineButton onClick={props.onLinkDifferentCard}>
          <SmallTextStyled>
            <b>{CONFIRM_CARD.CHANGE_CARD}</b>
          </SmallTextStyled>
        </InlineButton>
      </Flex>

      <Box minH="64px" />

      {!!CONFIRM_CARD.AUTHORIZATION && (
        <>
          <ExtraSmallText data-testid="ConfirmCardAuth">{CONFIRM_CARD.AUTHORIZATION}</ExtraSmallText>
          <Box minH="16px" />
        </>
      )}

      <PrimaryButton processing={props.isLoading} disabled={props.isLoading} onClick={props.onPressConfirm}>
        {CONFIRM_CARD.CTA}
      </PrimaryButton>
    </BasePageLayout>
  )
}

const SmallTextStyled = styled(SmallText)`
  font-family: ${(props) => props.theme.fonts.heading};
`

const ConfirmCardDetail = styled(Flex)`
  border-top: 2px solid ${(props) => props.theme.colors.cloud};
  border-bottom: 2px solid ${(props) => props.theme.colors.cloud};
  padding: 16px;
`
