import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { OfferStates } from '@genoa/middle-end'
import { GENERIC_ERROR_MODAL_CONTENT } from '@genoa/screen-content'
import { ContinueEvaluationResponse, EvaluationInstruction, useQueryError } from '@genoa/state-management'

import { useAuthState } from '../../../../contexts'
import { usePollingRequest, useReduxAction, useShowErrorMessageModal } from '../../../../hooks'
import { setOfferAction } from '../../../../modules/flex2/offer'
import { useContinueEvaluationMutation } from '../../../../modules/flexApi'
import { useAnalytics, useIterable, useLogger } from '../../../../providers'
import {
  createUnderwritingApprovedEvent,
  createUnderwritingDeniedEvent,
} from '../../../../providers/iterable/user-events'
import * as Routes from '../../../../routing/constants'
import { DocumentVerificationProcessing } from './DocumentVerificationProcessing'

export const DocumentVerificationProcessingContainer = () => {
  const analytics = useAnalytics()
  const { uid } = useAuthState()
  const setOfferState = useReduxAction(setOfferAction)
  const loggerV2 = useLogger('DocumentVerificationProcessingContainer')
  const navigate = useNavigate()
  const iterable = useIterable()
  const { showErrorMessage } = useShowErrorMessageModal()

  const [continueEvaluation, { error: continueEvaluationError }] = useContinueEvaluationMutation()

  const handleRejected = () => {
    const dataFields = { denial_reason: 'Denied' }
    iterable.addEvent(createUnderwritingDeniedEvent({ dataFields }))
    navigate(Routes.Onboarding.UPSELL_ELIGIBILITY, { replace: true })
  }

  const handlePendingAccept = () => {
    iterable.addEvent(createUnderwritingApprovedEvent())
    navigate(Routes.Onboarding.CUSTOMIZE_SCHEDULE, { replace: true })
  }

  const handleResubmit = () => {
    return navigate(Routes.Onboarding.DOCUMENT_VERIFICATION_RESUBMIT)
  }

  const handleStillPolling = () => {
    return navigate(Routes.Onboarding.DOCUMENT_VERIFICATION_LITTLE_LONGER)
  }

  const { startPolling: continueEvaluationPolling } = usePollingRequest<ContinueEvaluationResponse>(
    useCallback(async () => {
      const res = await continueEvaluation({ customerId: uid! }).unwrap()
      analytics.logEvent(Analytics.Events.IV_CONTINUE_EVALUATION_SUCCESS)

      const instruction = res?.data.instruction

      if (instruction === EvaluationInstruction.ErrorState) {
        showErrorMessage(GENERIC_ERROR_MODAL_CONTENT.TITLE, GENERIC_ERROR_MODAL_CONTENT.SUBTITLE, 'Close', () => {
          return navigate(Routes.Onboarding.DOCUMENT_VERIFICATION_RESUBMIT)
        })
      }
      const instructionIsReady =
        instruction === EvaluationInstruction.Resubmit || instruction === EvaluationInstruction.EvaluationCompleted

      if (instructionIsReady) {
        return res
      }
      return false
    }, [uid]),
    30000,
    5000
  )

  useQueryError(continueEvaluationError, {
    onAllErrors() {
      loggerV2.error('Error polling Continue Evaluation')
      analytics.logEvent(Analytics.Events.IV_CONTINUE_EVALUATION_FAILURE)
      showErrorMessage(GENERIC_ERROR_MODAL_CONTENT.TITLE, GENERIC_ERROR_MODAL_CONTENT.SUBTITLE, 'Close', () => {
        handleStillPolling()
      })
    },
  })

  const handlePolling = async () => {
    const result = await continueEvaluationPolling()

    switch (result?.data?.instruction) {
      case EvaluationInstruction.EvaluationCompleted:
        analytics.logEvent(Analytics.Events.IV_CONTINUE_EVALUATION_SUCCESS)
        setOfferState({ initialized: true, offer: result?.data?.risk_offer })
        if (result?.data?.risk_offer?.offer_state == OfferStates.PENDING_ACCEPT) {
          return handlePendingAccept()
        }

        if (result?.data?.risk_offer?.offer_state == OfferStates.DENIED) {
          return handleRejected()
        }
        break
      case EvaluationInstruction.Resubmit:
        return handleResubmit()
      default:
        return handleStillPolling()
    }
  }

  useEffect(() => {
    handlePolling()
  }, [])

  return <DocumentVerificationProcessing analyticsScreenName={Analytics.Screens.DOCUMENT_VERIFICATION} />
}
