import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { ActionLogType, FlexLinks } from '@genoa/domain'
import { LocalStorageProductType } from '@genoa/domain'
import { useRestartOnboarding } from '@genoa/middle-end'
import { CREDIT_BUILDER_VALUE_PROP as content } from '@genoa/screen-content'
import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useAuthState, useModal } from '../../../../../contexts'
import { useHandleRegisterActionLog } from '../../../../../hooks/flex2/onboarding-status'
import { isBlockedOffer } from '../../../../../hooks/flex2/risk'
import { useNavigateToOnboardingPropertySelection } from '../../../../../hooks/navigation'
import { useOfferState } from '../../../../../modules/flex2/use-state'
import { useAnalytics, useHelpLinks } from '../../../../../providers'
import * as Routes from '../../../../../routing/constants'
import { Gap, SmallText } from '../../../../components'
import { CreditBuilderValueProp } from './CreditBuilderValueProp'

export const CreditBuilderValuePropContainer = () => {
  const analytics = useAnalytics()
  const { user } = useAuthState()
  const offerState = useOfferState()
  const helpLinks = useHelpLinks()
  const modal = useModal()
  const navigate = useNavigate()

  const [_, restartOnboarding] = useRestartOnboarding()
  const navigateToPropertySelection = useNavigateToOnboardingPropertySelection()
  const { handleRegisterActionLog, loadingRegisterActionLog } = useHandleRegisterActionLog()

  const onClickLearnMore = () => {
    analytics.logEvent(Analytics.Events.CREDIT_BUILDER_VALUE_PROP_LEARN_MORE_CTA)
    helpLinks.open(FlexLinks.creditBuilderCreditScore)
  }
  const onClickContinue = async () => {
    analytics.logEvent(Analytics.Events.CREDIT_BUILDER_VALUE_PROP_CONTINUE_CTA)
    await handleRegisterActionLog(ActionLogType.VALUE_PROP)
    navigateToPropertySelection()
  }

  const onClickContinueToSwapRentSplit = async () => {
    analytics.logEvent(Analytics.Events.CREDIT_BUILDER_SWAP_CONTINUE_CLICKED)
    localStorage.setItem('flexapp/product', LocalStorageProductType.RENT_SPLIT)

    if (user?.uid && !isBlockedOffer(offerState)) {
      await restartOnboarding({ customerPublicId: user.uid })
    }
    navigate(Routes.Onboarding.CREATE_ACCOUNT)
  }

  const onClickNevermind = () => {
    analytics.logEvent(Analytics.Events.CREDIT_BUILDER_SWAP_NEVER_MIND_CLICKED)

    modal.close()
  }

  const onClickSwapToFlexibleRent = () => {
    analytics.logEvent(Analytics.Events.CREDIT_BUILDER_SWAP_LINK_CLICKED)

    modal.show({
      title: content.RENT_SPLIT_MODAL.HEADER,
      cta: content.RENT_SPLIT_MODAL.CTA,
      onCTAclicked: onClickContinueToSwapRentSplit,
      hasCloseButton: false,
      render: () => (
        <SmallText>
          <BodyTextWrapper>
            <SmallText>{content.RENT_SPLIT_MODAL.MAIN_TEXT_1}</SmallText>
            <Gap size="spacing_100" />
            <SmallText>{content.RENT_SPLIT_MODAL.MAIN_TEXT_2}</SmallText>
          </BodyTextWrapper>
          <Gap size="spacing_200" />
          <NeverMindLink fontWeight="bold" color="jewelPurple" onClick={onClickNevermind}>
            {content.RENT_SPLIT_MODAL.NEVER_MIND}
          </NeverMindLink>
          <Gap size="spacing_100" />
        </SmallText>
      ),
    })
  }

  return (
    <CreditBuilderValueProp
      analyticsScreenName={Analytics.Screens.ONBOARDING_CB_VALUE_PROP}
      onClickLearnMore={onClickLearnMore}
      onClickContinue={onClickContinue}
      loadingSubmit={loadingRegisterActionLog}
      onClickSwapToFlexibleRent={onClickSwapToFlexibleRent}
    />
  )
}

const BodyTextWrapper = styled(Flex)`
  text-align: left;
  flex-direction: column;
`

const NeverMindLink = styled(SmallText)`
  cursor: pointer;
`
