import { DestinationPlugin, Event, IdentifyEvent, Result } from '@amplitude/analytics-types'

import { allowsEnhancedTracking } from '../enhanced-tracking'

export type GTMContainerConfiguration = {
  isDebug: boolean
  containerId: string
}

export class GoogleTagManagerDestinationPlugin implements DestinationPlugin {
  private datalayer?: Record<string, any>[]
  private readonly containerId: string
  private knownUserId?: string
  private readonly _collected: Record<string, any> = {}

  readonly name: string
  readonly type = 'destination' as const

  static newInstance = (config?: GTMContainerConfiguration) => {
    if (config && config.containerId.length > 0) {
      const { isDebug, containerId } = config
      return new GoogleTagManagerDestinationPlugin(`${isDebug && '[Dev]'}Amplitude-GTMDestinationPlugin`, containerId)
    }
    return undefined
  }

  private constructor(pluginIdentifier: string, containerId: string) {
    this.name = pluginIdentifier
    this.containerId = containerId
  }

  async setup(): Promise<void> {
    this.datalayer = window.gtmDataLayer = window.gtmDataLayer || []
    this.datalayer?.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
    const head = document.getElementsByTagName('head')[0],
      script = document.createElement('script')
    script.async = true
    script.src = `https://www.googletagmanager.com/gtm.js?id=${this.containerId}&l=gtmDataLayer`
    head.insertBefore(script, head.firstChild)
  }

  async execute(event: Event): Promise<Result> {
    const { event_type: evtType } = event
    if (evtType === '$identify') {
      await this.handleIdentifyEvent(event as IdentifyEvent)
    } else if (allowsEnhancedTracking(evtType)) {
      // enhanced tracking involves forwarding this particular Amplitude event to GTM
      await this.handleGTMEvent(event)
    }
    // else we just let this Amplitude event pass through

    return {
      code: 200,
      event: event,
      message: 'event processed',
    }
  }

  public writeToDataLayer(..._: any[]) {
    // eslint-disable-next-line prefer-rest-params
    this.datalayer?.push(arguments)
  }

  private handleIdentifyEvent(event: IdentifyEvent): Promise<void> {
    const { user_id: userId, user_properties: properties } = event
    const newInformationDiscovered: Record<string, any> = {}

    // surface user ID if existing information doesn't match that from the incoming identity event
    if (userId !== undefined && this.knownUserId !== userId) {
      newInformationDiscovered.customerId = this.knownUserId = userId
    }

    // store ad network click identifiers if identity event contains gclid and/or fbclid to Amplitude
    if (properties.$set !== undefined) {
      const { gclid, fbclid } = properties.$set
      if (gclid !== undefined && this._collected.gclid !== gclid) {
        newInformationDiscovered.gclid = this._collected.gclid = gclid
      }
      if (fbclid !== undefined && this._collected.fbclid !== fbclid) {
        newInformationDiscovered.fbclid = this._collected.fbclid = fbclid
      }
    }

    // push to GTM Data Layer when the promise is resolved, if any new information was found
    if (Object.keys(newInformationDiscovered).length > 0) {
      return new Promise((resolve) => {
        this.datalayer?.push(newInformationDiscovered)
        resolve()
      })
    }

    return Promise.resolve()
  }

  private handleGTMEvent(event: Event): Promise<void> {
    const { event_type: eventName, event_properties: properties } = event

    // push event to GTM Data Layer when the promise is resolved
    return new Promise((resolve) => {
      this.datalayer?.push({
        event: eventName,
        ...this._collected,
        ...(properties || {}),
      })

      resolve()
    })
  }
}
