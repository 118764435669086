import { Route } from 'react-router-dom'

import { V2CongratsContainer } from '../../views/pages/congrats/V2CongratsContainer'
import { ApprovedContainer } from '../../views/pages/onboarding/approved'
import { AutopilotContainer } from '../../views/pages/onboarding/autopilot/AutoPilotContainer'
import { OnboardingAutopaySetupContainer } from '../../views/pages/onboarding/autopilot/OnboardingAutopaySetupContainer'
import { ChooseDateContainer } from '../../views/pages/onboarding/choose-date/ChooseDateContainer'
import { CustomizeYourScheduleContainer } from '../../views/pages/onboarding/customize-your-schedule/CustomizeYourScheduleContainer'
import { SoftCreditCheckStageContainer } from '../../views/pages/onboarding/soft-credit-check/SoftCreditCheckStageContainer'
import { V2SoftCreditCheckContainer } from '../../views/pages/onboarding/soft-credit-check/V2SoftCreditCheckContainer'
import { V2AcceptanceOfferDetailsContainer } from '../../views/pages/onboarding/v2-acceptance-offer-details/V2AcceptanceOfferDetailsContainer'
import * as Routes from '../constants'
import { getRouteSlugs } from '../utils'

const routeSlugs = getRouteSlugs(Routes.App.ONBOARDING, Routes.Onboarding)

export const SCHEDULE_SETUP_ROUTES = (
  <>
    <Route key="offer-details" path={routeSlugs.OFFER_DETAILS} element={<V2AcceptanceOfferDetailsContainer />} />
    <Route key="customize-schedule" path={routeSlugs.CUSTOMIZE_SCHEDULE} element={<CustomizeYourScheduleContainer />} />
    <Route key="choose-date" path={routeSlugs.CHOOSE_DATE} element={<ChooseDateContainer />} />
    <Route key="autopay" path={routeSlugs.AUTOPAY_SETUP} element={<OnboardingAutopaySetupContainer />} />
    <Route key="autopilot" path={routeSlugs.AUTOPILOT} element={<AutopilotContainer />} />
    <Route key="approved" path={routeSlugs.APPROVED} element={<ApprovedContainer />} />
    <Route key="congrats" path={routeSlugs.CONGRATS} element={<V2CongratsContainer />} />
    <Route key="soft-credit-check" path={routeSlugs.SOFT_CREDIT_CHECK} element={<V2SoftCreditCheckContainer />} />
    <Route
      key="soft-credit-check-stage-selection"
      path={routeSlugs.SOFT_CREDIT_CHECK_STAGE_SELECTION}
      element={<SoftCreditCheckStageContainer />}
    />
  </>
)
