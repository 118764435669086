import React from 'react'
import { Analytics } from '@genoa/analytics'
import { DOCUMENT_VERIFICATION_RESUBMIT } from '@genoa/screen-content'
import { Flex } from '@chakra-ui/react'

import { HeroUpdate } from '../../../assets'
import { Gap, Headline1, PrimaryButton, SmallText } from '../../../components'
import { BasePageLayout } from '../../../layouts'

interface DocumentVerificationProps {
  analyticsScreenName: Analytics.Screens
  onClickContinue: () => void
}

export const DocumentVerificationResubmit = (props: DocumentVerificationProps) => {
  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Flex alignItems="center" direction="column">
        <Gap size="spacing_200" />

        <HeroUpdate />

        <Gap size="spacing_200" />

        <Headline1>{DOCUMENT_VERIFICATION_RESUBMIT.HEADER}</Headline1>

        <Gap size="spacing_200" />

        <SmallText textAlign="center">{DOCUMENT_VERIFICATION_RESUBMIT.BODY}</SmallText>

        <Gap size="spacing_200" />
        <PrimaryButton onClick={props.onClickContinue} testID="DocumentVerificationResubmitContinueButton">
          {DOCUMENT_VERIFICATION_RESUBMIT.CTA}
        </PrimaryButton>
      </Flex>
    </BasePageLayout>
  )
}
