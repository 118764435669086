import React, { useCallback } from 'react'
import DayPicker from 'react-day-picker'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import 'react-day-picker/lib/style.css'
import { DatePickerHeader } from './date-picker-header/DatePickerHeader'
import { DatePickerModalListItem } from './DatePickerModalListItem'

type DatePickerPros = {
  onChangeMonth: (month: number) => void
  isPreviousMonthButtonDisabled: boolean
  onChangeYear: (year: string) => void
  isNextMonthButtonDisabled: boolean
  onSelectDate: (date: Date) => void
  onClickPreviousMonth: () => void
  onClickNextMonth: () => void
  errorMessage?: string
  dateSelected: Date
  minDate?: Date
  maxDate?: Date
}

const modifiersStyles = {
  outside: {
    backgroundColor: 'white',
  },
}

export const DatePicker = (props: DatePickerPros) => {
  const checkIsSameDate = useCallback((dateA: Date, dateB: Date): boolean => {
    const isSameYear = dateA.getFullYear() === dateB.getFullYear()
    const isSameMonth = dateA.getMonth() === dateB.getMonth()
    const isSameDay = dateA.getDate() === dateB.getDate()

    return isSameYear && isSameMonth && isSameDay
  }, [])

  return (
    <Container direction="column" alignItems="center">
      <DatePickerHeader
        isPreviousMonthButtonDisabled={props.isPreviousMonthButtonDisabled}
        isNextMonthButtonDisabled={props.isNextMonthButtonDisabled}
        onClickPreviousMonth={props.onClickPreviousMonth}
        onClickNextMonth={props.onClickNextMonth}
        onChangeMonth={props.onChangeMonth}
        onChangeYear={props.onChangeYear}
        errorMessage={props.errorMessage}
        date={props.dateSelected}
        minDate={props.minDate}
        maxDate={props.maxDate}
      />
      <Box>
        <DayPicker
          onMonthChange={(date: Date) => props.onChangeMonth(date.getMonth())}
          modifiersStyles={modifiersStyles}
          captionElement={() => null}
          initialMonth={props.dateSelected}
          pagedNavigation={false}
          month={props.dateSelected}
          navbarElement={() => null}
          renderDay={(date: Date, modifiers: object) => (
            <DatePickerModalListItem
              isSelected={checkIsSameDate(props.dateSelected, date)}
              onSelectDate={() => props.onSelectDate(date)}
              modifiers={modifiers}
              date={date}
            />
          )}
        />
      </Box>
    </Container>
  )
}

const Container = styled(Flex)`
  max-width: 100%;
  min-width: 100%;
`
