import {
  ContentOverhaulConfirmScheduleMar25Experiment,
  ContentOverhaulRentAmountMar25Experiment,
  ContentOverhaulSoftCreditCheckMar25Experiment,
} from '@genoa/experiments'

import { useSecureLineOfCredit } from '../secure-line-of-credit'
import { useExperimentVariant } from '../use-experiment-variant'

export const useContentOverhaul = () => {
  const { isFlagEnabledForCreditBuilder, isEnabledForSLC } = useSecureLineOfCredit()

  const contentOverhaulSoftCreditCheckMar25ExperimentVariant = useExperimentVariant(
    ContentOverhaulSoftCreditCheckMar25Experiment
  )
  const isContentOverhaulSoftCreditCheckMar25Enabled =
    contentOverhaulSoftCreditCheckMar25ExperimentVariant ===
    ContentOverhaulSoftCreditCheckMar25Experiment.variantValues.Treatment

  const contentOverhaulRentAmountMar25ExperimentVariant = useExperimentVariant(ContentOverhaulRentAmountMar25Experiment)

  const isContentOverhaulRentAmountMar25Enabled =
    contentOverhaulRentAmountMar25ExperimentVariant === ContentOverhaulRentAmountMar25Experiment.variantValues.Treatment
  const contentOverhaulConfirmScheduleMar25ExperimentVariant = useExperimentVariant(
    ContentOverhaulConfirmScheduleMar25Experiment
  )
  const isContentOverhaulConfirmScheduleMar25Enabled =
    contentOverhaulConfirmScheduleMar25ExperimentVariant ===
    ContentOverhaulConfirmScheduleMar25Experiment.variantValues.Treatment

  return {
    isSoftCreditCheckEnabled:
      isContentOverhaulSoftCreditCheckMar25Enabled && !isFlagEnabledForCreditBuilder && !isEnabledForSLC,
    isRentAmountEnabled: isContentOverhaulRentAmountMar25Enabled && !isFlagEnabledForCreditBuilder && !isEnabledForSLC,
    isConfirmScheduleEnabled:
      isContentOverhaulConfirmScheduleMar25Enabled && !isFlagEnabledForCreditBuilder && !isEnabledForSLC,
  }
}
