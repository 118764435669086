import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { Theme } from '@emotion/react'
import styled from '@emotion/styled'

import { CircleCheckmark } from '../../../../assets'
import { SpinnerLoading } from '../../../../components'

type LocationDataProps = {
  onPress: () => unknown
  theme: Theme
  isLoaded: boolean
  isLoading: boolean
  buttonText?: string
}

const RequestStatusIcon = (props: Omit<LocationDataProps, 'onPress' | 'theme'>) => {
  if (props.isLoading) {
    return <SpinnerLoading height="24px" />
  }

  if (props.isLoaded) {
    return <CircleCheckmark />
  }

  return null
}

export const LocationData = (props: LocationDataProps) => (
  <Box
    key="improve-results-with-location-data-container"
    onClick={props.onPress}
    data-testid="AddressSelectionLocationData"
  >
    <Flex flex={1} flexDirection="row" alignItems="center" justifyContent="flex-start">
      <StyledSmallText
        data-testid="AddressSelectionLocationDataText"
        color={props.theme.colors.brand[900]}
        cursor="pointer"
      >
        {props.buttonText}
      </StyledSmallText>
      <Box minW="10px" />
      <RequestStatusIcon isLoading={props.isLoading} isLoaded={props.isLoaded} />
    </Flex>
  </Box>
)

const StyledSmallText = styled(Flex)`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-family: ${(props) => props.theme.fonts.heading};
`
