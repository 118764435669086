import React from 'react'
import { Analytics } from '@genoa/analytics'
import { INCOME_VERIFICATION_PROCESSING_OFFER as content } from '@genoa/screen-content'
import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { WeightLift } from '../../../assets'
import { Gap, Headline1, Text } from '../../../components'
import { BasePageLayout } from '../../../layouts'

export const IncomeVerificationProcessingOffer = () => {
  return (
    <BasePageLayout analyticsScreenName={Analytics.Screens.INCOME_VERIFICATION_PROCESSING_OFFER} hideRestartButton>
      <Flex flexDirection="column" alignItems="center">
        <Gap size="spacing_200" />

        <WeightLift />

        <Gap size="spacing_200" />

        <Headline1>{content.HEADER}</Headline1>

        <Gap size="spacing_150" />

        <ProcessingOfferBodyText>{content.BODY_1}</ProcessingOfferBodyText>
        <Gap size="spacing_150" />
        <ProcessingOfferBodyText>{content.BODY_2}</ProcessingOfferBodyText>
      </Flex>
    </BasePageLayout>
  )
}

const ProcessingOfferBodyText = styled(Text)`
  color: ${(props) => props.theme.colors.dusk};
  text-align: center;
`
