/* eslint-disable no-console */
import { datadogLogs } from '@datadog/browser-logs'

import { Config } from '../config'

export enum LogLevels {
  ALL = 'all',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
}

const localEnvs: string[] = ['local', 'development']
const logLevels: string[] = Config.LOG_LEVEL || []

const showLocalLog = (logLevel: LogLevels) => {
  const isLocal = localEnvs.includes(Config.ENVIRONMENT)
  if (isLocal && (logLevels.includes(logLevel.toString()) || logLevels.includes(LogLevels.ALL.toString()))) {
    return true
  }
  return false
}

const handleInfo = (title: string, message?: string, context?: any) => {
  const normalizedContext = context || {}
  datadogLogs.logger.info(title, { content: message, ...normalizedContext })

  if (showLocalLog(LogLevels.INFO)) {
    console.info('INFO: ', title, message, context)
  }
}

const handleWarn = (title: string, message?: string, context?: any) => {
  const normalizedContext = context || {}
  datadogLogs.logger.warn(title, { content: message, ...normalizedContext })

  if (showLocalLog(LogLevels.WARN)) {
    console.warn('WARN: ', title, message, context)
  }
}

const handleError = (title: string, message?: string, context?: any) => {
  const normalizedContext = context || {}
  datadogLogs.logger.error(title, { content: message, ...normalizedContext })

  if (showLocalLog(LogLevels.ERROR)) {
    console.error('ERROR: ', title, message, context)
  }
}

const handleComponentName = (title: string, componentName: string) => {
  return `${componentName} - ${title}`
}

export const useLogger = (componentName: string) => {
  const info = (title: string, message?: string, context?: any) => {
    const titleWithComponent = handleComponentName(title, componentName)
    handleInfo(titleWithComponent, message, context)
  }

  const warn = (title: string, message?: string, context?: any) => {
    const titleWithComponent = handleComponentName(title, componentName)
    handleWarn(titleWithComponent, message, context)
  }

  const error = (title: string, message?: string, context?: any) => {
    const titleWithComponent = handleComponentName(title, componentName)
    handleError(titleWithComponent, message, context)
  }

  return {
    info,
    warn,
    error,
  }
}

export const loggerV2 = {
  info: handleInfo,
  warn: handleWarn,
  error: handleError,
}
