import { FlexAnywhereFlywheelExperiment } from '@genoa/experiments'

import { useExperimentVariant } from '../use-experiment-variant'

export const useFlexAnywhereFlywheelExperiment = () => {
  const FlexAnywhereFlywheelExperimentVariant = useExperimentVariant(FlexAnywhereFlywheelExperiment)
  const isFlexAnywhereFlywheelEnabled =
    FlexAnywhereFlywheelExperimentVariant === FlexAnywhereFlywheelExperiment.variantValues.Treatment
  return {
    isFlexAnywhereFlywheelEnabled,
  }
}
