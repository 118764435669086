import { FlexAnywhereOnboardingUpdatesExperiment } from '@genoa/experiments'

import { useExperimentVariant } from '../use-experiment-variant'

export const useFlexAnywhereOnboardingUpdatesExperiment = () => {
  const FlexAnywhereOnboardingUpdatesExperimentVariant = useExperimentVariant(FlexAnywhereOnboardingUpdatesExperiment)
  const isFlexAnywhereOnboardingUpdatesEnabled =
    FlexAnywhereOnboardingUpdatesExperimentVariant === FlexAnywhereOnboardingUpdatesExperiment.variantValues.Treatment
  return {
    isFlexAnywhereOnboardingUpdatesEnabled,
  }
}
