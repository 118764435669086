import React from 'react'

import { PrimaryButton, SmallText } from '../../components'
import { Modal, ModalActions } from '../../components/Modal'

type WrongPinProps = {
  visible: boolean
  onClose: () => unknown
}

export const WrongPin = (props: WrongPinProps) => {
  return (
    <Modal testID="WrongPinModal" title="Whoops!" canClose onClose={props.onClose} visible={props.visible}>
      <SmallText>The code you entered is invalid. Check for mistakes or resend the code and try again.</SmallText>

      <ModalActions>
        <PrimaryButton onClick={props.onClose}>Ok</PrimaryButton>
      </ModalActions>
    </Modal>
  )
}
