import { useEffect } from 'react'
import { resetPrefillsAction, resetPropertyLinkingAction } from '@genoa/state-management'
import { resetEmbedAction, setEmbedAction } from '@genoa/state-management'
import { EmbedType } from '@genoa/state-management'
import { isInIframe } from '@genoa/utils'

import { useEmbed } from '../../hooks'
import { Config, useLogger } from '../../providers'
import { useReduxAction } from '../use-redux-action'

export const useSetEmbedParams = () => {
  const setEmbed = useReduxAction(setEmbedAction)
  const resetEmbed = useReduxAction<void>(resetEmbedAction)
  const resetPropertyLinking = useReduxAction<void>(resetPropertyLinkingAction)
  const resetPrefills = useReduxAction<void>(resetPrefillsAction)
  const embed = useEmbed()
  const inIframe = isInIframe()
  const logger = useLogger('useSetEmbedParams')

  const checkIfOriginWhitelisted = (origin: string): boolean => {
    const isWhitelistOrigin = !!Config.EMBED_ORIGINS_WHITELIST?.some((item: string) =>
      new RegExp(`https?:\/\/(www.)?[^/]*${item}$`).test(origin)
    )
    const isFlexOrigin = /https?:\/\/[^/]*getflex.com$/.test(origin)
    const isLocalhost = /https?:\/\/[^/]*localhost:300\d$/.test(origin)

    return isWhitelistOrigin || isFlexOrigin || isLocalhost
  }

  useEffect(() => {
    const hasEmbedParam = getHasEmbedParam()

    if (embed.isEmbed && !inIframe) {
      resetEmbed()
      resetPropertyLinking()
      resetPrefills()
    }

    const onMessage = (event: MessageEvent) => {
      const { data, origin } = event
      const isOriginWhiteWhitelisted = checkIfOriginWhitelisted(origin)
      if (!isOriginWhiteWhitelisted) {
        return
      }

      let isEmbedMessage = false
      let token
      let client
      try {
        if (typeof data === 'string') {
          const messageData = JSON.parse(data)
          if (messageData?.embed === 'embedMessage') {
            isEmbedMessage = true
            token = messageData.token
            client = messageData.clientId
          }
        }
      } catch (error) {
        const message = error instanceof Error ? error?.message : 'An unexpected error occured'
        logger.error('error on message', `error: ${message}`)
      }

      if (hasEmbedParam && client && token && isEmbedMessage) {
        setEmbed({ client, isEmbed: true, origin, token, type: EmbedType.modal })
        resetPrefills()
      }
    }

    if (hasEmbedParam && inIframe) {
      window.addEventListener('message', onMessage)
    }

    return () => {
      if (hasEmbedParam && inIframe) {
        window.removeEventListener('message', onMessage)
      }
    }
  }, [])
}

const getHasEmbedParam = () => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get('e') === '1'
}
