import { Control, FormState, UseFormReturn } from 'react-hook-form'
import { Analytics } from '@genoa/analytics'
import { SLC_SOFT_CREDIT_CHECK_CONTENT as content } from '@genoa/screen-content'
import { Box, Flex, UnorderedList, useTheme } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { QuestionCircle } from '../../../../assets'
import {
  ExtraSmallText,
  Field,
  Headline1,
  InlineButton,
  PrimaryButton,
  SmallText,
  TextInput,
} from '../../../../components'
import { Gap } from '../../../../components'
import { MoneyInput } from '../../../../components/Input'
import { BasePageLayout } from '../../../../layouts'
import { useIncomeCollectionExperiment } from './use-income-collection-experiment'
import { useSoftCreditCheckModal } from './use-soft-credit-check-modal'

export type SoftCreditCheckState = {
  dob: string
  ssn: string
  income: string
}

type SLCSoftCreditCheckProps = {
  analyticsScreenName: Analytics.Screens
  onDateChange: (date: string) => void
  onSetSSN: (ssn: string) => void
  onSetIncome: (income: string) => void
  onClickContinue: () => void
  ssnPlaceholder: string
  isLoading: boolean
  control: Control<SoftCreditCheckState>
  formErrors: FormState<SoftCreditCheckState>['errors']
  getValues: UseFormReturn<SoftCreditCheckState>['getValues']
}

export const SLCSoftCreditCheck = (props: SLCSoftCreditCheckProps) => {
  const theme = useTheme()
  const isIncomeCollectionEnabled = useIncomeCollectionExperiment()
  const softCreditCheckModal = useSoftCreditCheckModal()

  const ssn = props.getValues('ssn')
  const date = props.getValues('dob')
  const income = props.getValues('income')
  const baseDisabled = !ssn || !date || props.isLoading

  return (
    <>
      <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
        <Flex direction="column">
          <Gap size="spacing_200" />

          <Headline1>{content.TITLE}</Headline1>

          <Gap size="spacing_150" />

          <SmallText>{content.BODY}</SmallText>
          <Gap size="spacing_150" />
          {isIncomeCollectionEnabled ? (
            <UnorderedList>
              {content.BULLETS.map((v, i) => (
                <SmallText key={i}>
                  <li>{v}</li>
                </SmallText>
              ))}
            </UnorderedList>
          ) : (
            <Box flex={1}>
              <Row>
                <SmallText fontWeight="bold">This will not affect your credit score </SmallText>
                <LearnMoreButton testID="SoftCreditCheckLearnMore" onClick={softCreditCheckModal.openLearnMoreModal}>
                  <LearnMoreButtonContent>
                    <QuestionCircle />
                  </LearnMoreButtonContent>
                </LearnMoreButton>
              </Row>
            </Box>
          )}
          <Gap size="spacing_200" />

          <Box>
            <Field
              control={props.control}
              defaultValue={date}
              error={props.formErrors.dob}
              input={TextInput}
              label="Date of birth"
              name="dob"
              onChange={props.onDateChange}
              placeholder="MM / DD / YYYY"
              preventChangeValue
              testID="SoftCreditCheckDateOfBirthInput"
            />
          </Box>

          <Gap size="spacing_100" />

          <Box>
            <Field
              control={props.control}
              error={props.formErrors.ssn}
              input={TextInput}
              label="Social security number"
              name="ssn"
              onChange={props.onSetSSN}
              placeholder={props.ssnPlaceholder}
              preventChangeValue
              testID="SoftCreditCheckSsnInput"
            />
          </Box>
          <Gap size="spacing_200" />
          <FederalLawDisclaimerWrapper>
            <ExtraSmallText>
              {content.FEDERAL_LAW_DISCLAIMER}
              <WhyText
                color={theme.colors.jewelPurple}
                onClick={
                  isIncomeCollectionEnabled
                    ? softCreditCheckModal.openInfoCollectionModal
                    : softCreditCheckModal.openWhyWeCollectInfoModal
                }
              >
                {content.LINK_TEXT}
              </WhyText>
            </ExtraSmallText>
          </FederalLawDisclaimerWrapper>
          <Box>
            {isIncomeCollectionEnabled && (
              <Field
                control={props.control}
                error={props.formErrors.income}
                input={MoneyInput}
                label={
                  <>
                    {content.INCOME_LABEL}
                    <LabelQuestionArea as="span">
                      <InlineButton onClick={softCreditCheckModal.openIncomeCollectionModal}>
                        <QuestionCircle />
                      </InlineButton>
                    </LabelQuestionArea>
                  </>
                }
                maxLength={8}
                name="income"
                onChange={props.onSetIncome}
                preventChangeValue
                testID="SoftCreditCheckIncomeInput"
              />
            )}
          </Box>

          <Gap size="spacing_400" />

          <ExtraSmallText>{content.AUTHORIZATION}</ExtraSmallText>

          <Gap size="spacing_150" />

          <PrimaryButton
            disabled={baseDisabled || (isIncomeCollectionEnabled && !income)}
            processing={props.isLoading}
            onClick={props.onClickContinue}
            testID="SoftCreditCheckContinueButton"
          >
            Continue
          </PrimaryButton>

          <Gap size="spacing_100" />
        </Flex>
      </BasePageLayout>
    </>
  )
}

const LearnMoreButton = styled(Box)`
  display: inline-flex;
  cursor: pointer;
  padding-left: ${(props) => props.theme.fixedSizes.spacing_37_5};
`

const LearnMoreButtonContent = styled(SmallText)`
  color: ${(props) => props.theme.colors.brand['900']};
`
const FederalLawDisclaimerWrapper = styled(Flex)`
  background-color: ${(props) => props.theme.colors.cloud};
  border-radius: ${(props) => props.theme.fixedSizes.spacing_50};
  padding: ${(props) => props.theme.fixedSizes.spacing_50};
  justify-content: center;
  align-items: center;
`

const WhyText = styled(ExtraSmallText)`
  font-weight: bold;
  cursor: pointer;
`

const LabelQuestionArea = styled(Box)`
  margin-left: ${(props) => props.theme.fixedSizes.spacing_25};
`
const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`
