import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'

import * as Routes from '../../../../routing/constants'
import { DocumentVerificationResubmit } from './DocumentVerificationResubmit'

export const DocumentVerificationResubmitContainer = () => {
  const navigate = useNavigate()

  const handleResubmit = () => {
    return navigate(Routes.Onboarding.DOCUMENT_VERIFICATION)
  }

  return (
    <DocumentVerificationResubmit
      analyticsScreenName={Analytics.Screens.DOCUMENT_VERIFICATION_RESUBMIT}
      onClickContinue={handleResubmit}
    />
  )
}
