import { BillerLocationData, BillerPropertyDetails, IntegrationType } from '@genoa/domain'

export type BillerPropertyDetailsWithLocation = BillerPropertyDetails & { readonly location: BillerLocationData }

export const assertBillerLocationData = (location?: any): void => {
  if (!location || !location.address_line_1 || !location.city || !location.state || !location.postal_code) {
    throw new Error('Missing or malformed location in property details from biller response')
  }
}

export const assertBillerPropertyDetails = (details?: any): BillerPropertyDetailsWithLocation => {
  if (
    !details ||
    !details.integration_type ||
    !details.property_id ||
    !details.status ||
    !details.external_property_id
  ) {
    throw new Error('Missing required base in property details from biller response')
  }

  assertBillerLocationData(details.location)

  switch (details.integration_type) {
    case IntegrationType.PORTAL:
    case IntegrationType.DIRECT_INTEGRATION:
    case IntegrationType.EMBED:
      return details
    case IntegrationType.YARDI:
    case IntegrationType.REALPAGE:
      if (!details.external_pmc_id) {
        throw new Error('Missing external pmc id in property details from biller response')
      }

      return details
    default:
      throw new Error('Unhandled integration_type in property details from biller response')
  }
}
