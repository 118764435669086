import { ReinforcementMessage } from '@genoa/domain'
import { SLC_CUSTOMIZE_SCHEDULE_CONTENT } from '@genoa/screen-content'
import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { Attention, CheckCircleGreen } from '../../../../assets'
import { SmallText } from '../../../../components'

export interface ReinforcementMessageWidgetProps {
  readonly showReinforcementMessage: ReinforcementMessage | undefined
}

const messageConfig = {
  [ReinforcementMessage.FIRST_POSITIVE]: {
    icon: CheckCircleGreen,
    header: SLC_CUSTOMIZE_SCHEDULE_CONTENT.FIRST_PAYMENT_MESSAGE.POSITIVE_HEADER,
    body: SLC_CUSTOMIZE_SCHEDULE_CONTENT.FIRST_PAYMENT_MESSAGE.POSITIVE_BODY,
    testId: 'PositiveReinforcementMessageIcon',
  },
  [ReinforcementMessage.FIRST_NEGATIVE]: {
    icon: Attention,
    header: SLC_CUSTOMIZE_SCHEDULE_CONTENT.FIRST_PAYMENT_MESSAGE.NEGATIVE_HEADER,
    body: SLC_CUSTOMIZE_SCHEDULE_CONTENT.FIRST_PAYMENT_MESSAGE.NEGATIVE_BODY,
    testId: 'NegativeReinforcementMessageIcon',
  },
  [ReinforcementMessage.SECOND_POSITIVE]: {
    icon: CheckCircleGreen,
    header: SLC_CUSTOMIZE_SCHEDULE_CONTENT.SECOND_PAYMENT_MESSAGE.POSITIVE_HEADER,
    body: SLC_CUSTOMIZE_SCHEDULE_CONTENT.SECOND_PAYMENT_MESSAGE.POSITIVE_BODY,
    testId: 'PositiveReinforcementMessageIcon',
  },
  [ReinforcementMessage.SECOND_NEGATIVE]: {
    icon: Attention,
    header: SLC_CUSTOMIZE_SCHEDULE_CONTENT.SECOND_PAYMENT_MESSAGE.NEGATIVE_HEADER,
    body: SLC_CUSTOMIZE_SCHEDULE_CONTENT.SECOND_PAYMENT_MESSAGE.NEGATIVE_BODY,
    testId: 'NegativeReinforcementMessageIcon',
  },
}

export const ReinforcementMessageWidget = ({ showReinforcementMessage }: ReinforcementMessageWidgetProps) => {
  if (!showReinforcementMessage) return null

  const config = messageConfig[showReinforcementMessage]

  if (!config) return null

  const Icon = config.icon

  return (
    <Wrapper data-testid={config.testId.replace('Icon', '')}>
      <Flex>
        <IconWrapper>
          <Icon data-testid={config.testId} />
        </IconWrapper>
        <Flex direction="column">
          <HeaderSmallText fontWeight="bold">{config.header}</HeaderSmallText>
          <SmallText>{config.body}</SmallText>
        </Flex>
      </Flex>
    </Wrapper>
  )
}

const Wrapper = styled(Flex)`
  background-color: ${(props) => props.theme.colors.cloud};
  padding: ${(props) => props.theme.fixedSizes.spacing_100};
  border-radius: ${(props) => props.theme.radii.lg};
`

const IconWrapper = styled(Flex)`
  padding-right: ${(props) => props.theme.fixedSizes.spacing_100};
  align-items: center;
`

const HeaderSmallText = styled(SmallText)`
  padding-bottom: ${(props) => props.theme.fixedSizes.sm};
`
