import { IntegrationType } from '@genoa/domain'

import { RootState } from '../../modules'
import { useReduxSelector } from '../use-redux-selector'

export const useInNetworkFlexAnywhereUser = () => {
  const openSearchProperty = useReduxSelector((state: RootState) => state.propertyLinking.openSearchProperty)

  return openSearchProperty?.integration_type === IntegrationType.FLEX_ANYWHERE
}
