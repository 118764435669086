import React from 'react'

import { PrimaryButton, SmallText } from '../../components'
import { Modal, ModalActions } from '../../components/Modal'

type PinSendingProblemProps = {
  visible: boolean
  onClose: () => unknown
  message: string
}

export const PinSendingProblem = (props: PinSendingProblemProps) => {
  return (
    <Modal testID="PinSendingProblemModal" title="Whoops!" canClose onClose={props.onClose} visible={props.visible}>
      <SmallText>{props.message}</SmallText>

      <ModalActions>
        <PrimaryButton onClick={props.onClose}>Go back</PrimaryButton>
      </ModalActions>
    </Modal>
  )
}
