import { Controller, useFormContext } from 'react-hook-form'
import { Box, Stack } from '@chakra-ui/react'

import { RadioButton } from '../RadioButton'
import { SmallText } from '../Typography'
import { Field } from './Field'

// TODO make styles more customizable, for now the design will be hardcoded according to the P2P waitlist form

export interface RadioGroupOption {
  label: string
  value: string
}

type RadioGroupProps = {
  name: string
  title: string
  showTitle?: boolean
  options: RadioGroupOption[]
  allowCustomInput?: boolean
  customInputConfig?: {
    value: string
    placeholder?: string
    maxLength?: number
  }
  onOptionSelect?: (value: string) => void
}

export function RadioGroup({
  name,
  title,
  options,
  allowCustomInput,
  customInputConfig,
  showTitle = true,
  onOptionSelect,
}: RadioGroupProps) {
  const { control } = useFormContext()

  const hasCustomValue = (value: string) => {
    if (!customInputConfig?.value) {
      return false
    }
    return value === customInputConfig.value || value?.startsWith(`${customInputConfig.value}:`)
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Stack justifyItems="center" alignItems="start">
          {!!showTitle && <SmallText fontWeight="bold">{title}</SmallText>}
          {options.map((option) => (
            <>
              <RadioButton
                key={option.value}
                label={option.label}
                isSelected={
                  field.value === option.value ||
                  (option.value === customInputConfig?.value && hasCustomValue(field.value))
                }
                onClick={() => {
                  field.onChange(option.value)
                  onOptionSelect?.(option.value)
                }}
                testID={`radio-${option.value}`}
              />
              {allowCustomInput && option.value === customInputConfig?.value && hasCustomValue(field.value) && (
                <Box key="other-text">
                  <Field
                    name={customInputConfig.value}
                    control={control}
                    maxLength={customInputConfig.maxLength}
                    onChange={(text: string) => {
                      field.onChange(`${customInputConfig.value}:${text}`)
                    }}
                    placeholder={customInputConfig.placeholder}
                    label=""
                  />
                </Box>
              )}
            </>
          ))}
        </Stack>
      )}
    />
  )
}
