import { Analytics } from '@genoa/analytics'
import { IV_WAITING_OFFER, WAITING_OFFER } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { HeroSocks as HangTightImage } from '../../../assets'
import { Gap, SpinnerLoading } from '../../../components'
import { Headline1, SmallText, Text } from '../../../components'
import { BasePageLayout } from '../../../layouts'

type WaitingOfferProps = {
  analyticsScreenName: Analytics.Screens
  isIncomeVerification?: boolean
}

export const WaitingOffer = (props: WaitingOfferProps) => {
  const content = props.isIncomeVerification ? IV_WAITING_OFFER : WAITING_OFFER

  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Gap size="spacing_400" />

      <Flex align="center" justify="center">
        <HangTightImage />
      </Flex>

      <Gap size="spacing_125" />

      <Container flex={1} flexDirection="column" px="lg" alignItems="center">
        <Headline1>{content.HEADER}</Headline1>

        <Gap size="spacing_150" />

        <Text textAlign="center" data-testid="WaitingOfferBody">
          {content.BODY}
        </Text>

        <Gap size="spacing_150" />

        <SmallText textAlign="center" fontWeight="bold" data-testid="WaitingOfferBodyBold">
          {content.BODY_BOLD}
        </SmallText>

        <Gap size="spacing_400" />

        <Box>
          <SpinnerLoading />
        </Box>
      </Container>
    </BasePageLayout>
  )
}

const Container = styled(Flex)`
  height: 100%;
`
