import { LineItemType, PaymentCategoryV2, PaymentStatusFull } from '@genoa/domain'
import { getDateTimeFromISO } from '@genoa/utils'

/**
 * utility function for transforming the paymentStatus response into the Installments interfae expected by
 * PaymentSchedule. This transformation is necessary to continue using the existing component in onboarding.
 * The onboarding use case of the PaymentSchedule component has legal & compliance considerations that make it
 * poorly suited for swapping with the shared PaymentStatusSchedule component.
 *
 * @param payments - return value from paymentStatus endpoint
 *
 * @returns paymentStatusPayments - an array of the non-membership payment items,
 * formatted to the Installment interface, as expected by PaymentSchedule.tsx
 * @returns membershipFee - the total_amount_cents of the paymentStatusPayment with type MMF
 */
export const paymentStatusPaymentsToPaymentScheduleItems = (payments: PaymentStatusFull[]) => {
  const dayFromDate = (dateString: string): string => {
    const date = getDateTimeFromISO(dateString)
    const dayWithoutLeadingZero = date.toFormat('d')
    return dayWithoutLeadingZero
  }

  const paymentStatusPayments = payments
    ?.filter((payment) => payment.category !== PaymentCategoryV2.MMF)
    ?.map((payment) => {
      const baseAmountLineItem = payment?.line_items?.find((p) => p.line_item_type === LineItemType.BASE)
      const processingFeeLineItem = payment?.line_items?.find((p) => p.line_item_type === LineItemType.PROCESSING_FEE)

      return {
        baseAmount: baseAmountLineItem?.amount_cents,
        processingFeeAmount: processingFeeLineItem?.amount_cents,
        processingFeePercentage: processingFeeLineItem?.processing_fee_percent,
        totalAmount: payment.total_amount_cents,
        day: Number(dayFromDate(payment.due_date)),
      }
    })

  const membershipFee = payments?.find((payment) => payment.category === PaymentCategoryV2.MMF)?.total_amount_cents

  return { paymentStatusPayments, membershipFee }
}
