import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { Theme, useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { useContent } from '../../../../providers/content'
import { CheckmarkCirclePurple } from '../../../assets'
import { Headline2, InlineButton, LargeText, PrimaryButton, SmallText, Text } from '../../../components'
import { BasePageLayout } from '../../../layouts'

type Autopilot = {
  loading?: boolean
  handleNavigation: () => void
  handleSkipAutopilot: () => void
}

export const Autopilot = (props: Autopilot) => {
  const theme = useTheme()
  const {
    content: { AUTOPILOT },
  } = useContent()

  return (
    <>
      <BasePageLayout>
        <Box minH={theme.fixedSizes.spacing_200} />
        <Headline2>{AUTOPILOT.HEADER}</Headline2>
        <Box minH={theme.fixedSizes.spacing_100} />
        <SmallText>{AUTOPILOT.BODY}</SmallText>
        <CheckListContainer>
          {AUTOPILOT.CHECKED_LIST.map((message: string, index: number) => (
            <CheckListItem text={message} key={`CheckedList${index}`} />
          ))}
        </CheckListContainer>
        <NumberListContainer>
          <NumberListTitleText color={theme.colors.dusk}>{AUTOPILOT.NUMBERED_LIST_HEADER}</NumberListTitleText>
          {AUTOPILOT.NUMBERED_LIST.map(({ TEXT, BOLD_TEXT }: any, index: number) => (
            <NumberListItem
              theme={theme}
              number={index + 1}
              textBold={BOLD_TEXT}
              text={TEXT}
              key={`NumberedList${index}`}
            />
          ))}
        </NumberListContainer>
        <Box minH={theme.fixedSizes.spacing_175} />
        <FooterContainer>
          <SmallText>{AUTOPILOT.FOOTER}</SmallText>
          <Box minW={theme.fixedSizes.spacing_25} />
          <InlineButton onClick={props.handleSkipAutopilot} fontSize="14px" textColor={theme.colors.jewelPurple}>
            <b>{AUTOPILOT.FOOTER_BUTTON}</b>
          </InlineButton>
        </FooterContainer>
        <Box minH={theme.fixedSizes.spacing_100} />
        <PrimaryButton onClick={props.handleNavigation} testID="SetUpAutopayButton">
          {AUTOPILOT.CTA}
        </PrimaryButton>
      </BasePageLayout>
    </>
  )
}

const CheckListItem = ({ text }: { text: string }) => (
  <CheckListItemContainer>
    <CheckmarkCirclePurple />
    <CheckListText>{text}</CheckListText>
  </CheckListItemContainer>
)

const NumberListItem = ({
  text,
  textBold,
  theme,
  number,
}: {
  text: string
  textBold: string
  theme: Theme
  number: number
}) => (
  <NumberListItemContainer>
    <NumberListNumber color={theme.colors.jewelPurple}>{number}</NumberListNumber>
    <NumberListText>
      <SmallText fontWeight="bold">{textBold}</SmallText>
      {` ${text}`}
    </NumberListText>
  </NumberListItemContainer>
)

const CheckListContainer = styled(Box)`
  margin-top: ${(props) => props.theme.fixedSizes.spacing_75};
`

const CheckListText = styled(Text)`
  padding-left: ${(props) => props.theme.fixedSizes.spacing_50};
  font-weight: 600;
`

const CheckListItemContainer = styled(Flex)`
  align-items: center;
  flex-direction: row;
  margin-top: ${(props) => props.theme.fixedSizes.spacing_100};
  margin-left: ${(props) => props.theme.fixedSizes.spacing_100};
`

const NumberListContainer = styled(Box)`
  margin-top: ${(props) => props.theme.fixedSizes.spacing_175};
  padding-left: ${(props) => props.theme.fixedSizes.spacing_125};
  padding-top: ${(props) => props.theme.fixedSizes.spacing_100};
  padding-bottom: ${(props) => props.theme.fixedSizes.spacing_125};
  background-color: ${(props) => props.theme.colors.wildflower};
  border-radius: ${(props) => props.theme.fixedSizes.spacing_125};
`

const NumberListTitleText = styled(LargeText)`
  font-weight: 700;
`

const NumberListItemContainer = styled(Flex)`
  align-items: center;
  flex-direction: row;
  padding-top: ${(props) => props.theme.fixedSizes.spacing_50};
`

const NumberListText = styled(SmallText)`
  flex: 0.9;
`

const NumberListNumber = styled(Text)`
  padding-left: ${(props) => props.theme.fixedSizes.spacing_37_5};
  padding-right: ${(props) => props.theme.fixedSizes.spacing_175};
  font-size: ${(props) => props.theme.fixedSizes.spacing_100};
  font-weight: 600;
`

const FooterContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  padding-vertical: ${(props) => props.theme.fixedSizes.spacing_125};
  flex-direction: row;
  flex-wrap: wrap;
`
