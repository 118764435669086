import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'

import { useFlexAnywhereFlywheelExperiment, useSecureLineOfCredit } from '../../../../hooks'
import * as Routes from '../../../../routing/constants'
import { InitiateOfferRentAmountContainer } from '../../common/rent-amount/InitiateOfferRentAmountContainer'

export const OnboardingRentAmountContainer = () => {
  const navigate = useNavigate()
  const { refetchSLC } = useSecureLineOfCredit()
  const { isFlexAnywhereFlywheelEnabled } = useFlexAnywhereFlywheelExperiment()

  async function handleOnNext() {
    await refetchSLC()
    navigate(Routes.Onboarding.SOFT_CREDIT_CHECK)
  }

  const handleOnBack = () => {
    if (isFlexAnywhereFlywheelEnabled) {
      navigate(Routes.Onboarding.INVITE_YOUR_PROPERTY)
    } else {
      navigate(Routes.Onboarding.FLEX_ANYWHERE_HOW_YOU_PAY)
    }
  }

  return (
    <InitiateOfferRentAmountContainer
      analyticsScreenName={Analytics.Screens.ONBOARDING_RENT_AMOUNT}
      analyticsEventCTAClicked={Analytics.Events.RENT_AMOUNT_CTA_CLICKED}
      analyticsEventCTAError={Analytics.Events.RENT_AMOUNT_CTA_ERROR}
      onNext={handleOnNext}
      onBack={handleOnBack}
    />
  )
}
