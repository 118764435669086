import React from 'react'
import { Analytics } from '@genoa/analytics'
import { EMBED_RELINK_VERIFY_IDENTITY } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { HeroSocks as HangTightImage } from '../../../../../assets'
import { Gap, Headline1, SmallText, Text } from '../../../../../components'
import { SpinnerLoading } from '../../../../../components/SpinnerLoading/SpinnerLoading'
import { BasePageLayout } from '../../../../../layouts'

export const VerifyingIdentity = () => (
  <BasePageLayout analyticsScreenName={Analytics.Screens.EMBED_RELINK_VERIFYING}>
    <Gap size="spacing_400" />

    <Flex align="center" justify="center">
      <HangTightImage />
    </Flex>

    <Gap size="spacing_125" />

    <Container flex={1} flexDirection="column" px="lg" alignItems="center">
      <Headline1>{EMBED_RELINK_VERIFY_IDENTITY.VERIFYING1}</Headline1>

      <Gap size="spacing_150" />

      <Text textAlign="center">{EMBED_RELINK_VERIFY_IDENTITY.VERIFYING2}</Text>

      <Gap size="spacing_150" />

      <SmallText textAlign="center" fontWeight="bold">
        {EMBED_RELINK_VERIFY_IDENTITY.VERIFYING3}
      </SmallText>

      <Gap size="spacing_400" />

      <Box>
        <SpinnerLoading />
      </Box>
    </Container>
  </BasePageLayout>
)

const Container = styled(Flex)`
  height: 100%;
`
