import { Analytics } from '@genoa/analytics'
import { InAppPaymentType } from '@genoa/domain'
import {
  EMBED_MANUAL_PAY_PAY_NOW_DEFAULT_PAYMENT_FAILURE_MODAL,
  EMBED_MANUAL_PAY_PAY_NOW_FAIL_MODAL,
  EMBED_MANUAL_PAY_PAY_NOW_GENERIC_PAYMENT_FAILURE_MODAL,
  EMBED_MANUAL_PAY_PAY_NOW_INSUFFICIENT_FUNDS_MODAL,
  EMBED_MANUAL_PAY_PAY_NOW_PARTIAL_PAYMENT_CARD_FAILURE_MODAL,
  EMBED_MANUAL_PAY_PAY_NOW_PARTIAL_PAYMENT_GENERIC_MODAL,
  EMBED_MANUAL_PAY_PAY_NOW_UPDATE_CARD_MODAL,
  replaceContent,
} from '@genoa/screen-content'
import { getDateTimeFromISO, ordinalSuffixFor } from '@genoa/utils'
import { Box } from '@chakra-ui/react'

import { useModal } from '../../contexts'
import { useCustomerStatusState } from '../../modules/flex2/use-state'
import { useAnalytics } from '../../providers'
import { AppStore, Gap, SmallText } from '../../views/components'

export const usePayNowErrorModals = () => {
  const analytics = useAnalytics()
  const modal = useModal()
  const customerStatus = useCustomerStatusState()
  const bpMonth = getDateTimeFromISO(customerStatus.customerStatus.bp_end_date).set({ day: 5 })
  const bpMonthString = `${bpMonth.weekdayLong}, ${bpMonth.monthLong} ${ordinalSuffixFor(bpMonth.day)}`

  const displayInsufficientFundsModal = (paymentAmountString: string) => {
    analytics.logEvent(Analytics.Events.EPISODIC_PAYMENT_FAILURE_MODAL, { failure: 'insufficientFunds' })

    const text1 = replaceContent(EMBED_MANUAL_PAY_PAY_NOW_INSUFFICIENT_FUNDS_MODAL.TEXT_1, {
      paymentAmount: paymentAmountString,
    })

    modal.show({
      title: 'Couldn’t complete payment',
      cta: 'Close',
      render: () => (
        <Box>
          <SmallText>{text1}</SmallText>
          <Gap size="spacing_100" />
          <SmallText>{EMBED_MANUAL_PAY_PAY_NOW_INSUFFICIENT_FUNDS_MODAL.TEXT_2}</SmallText>
          <Gap size="spacing_100" />
          <SmallText>{EMBED_MANUAL_PAY_PAY_NOW_INSUFFICIENT_FUNDS_MODAL.TEXT_3}</SmallText>
          <Gap size="spacing_100" />
          <AppStore />
        </Box>
      ),
    })
  }

  const displayDefaultPaymentFailureModal = (paymentAmountString: string) => {
    analytics.logEvent(Analytics.Events.EPISODIC_PAYMENT_FAILURE_MODAL, { failure: 'defaultPaymentFailure' })

    const text1 = replaceContent(EMBED_MANUAL_PAY_PAY_NOW_DEFAULT_PAYMENT_FAILURE_MODAL.TEXT_1, {
      paymentAmount: paymentAmountString,
    })
    const text3 = replaceContent(EMBED_MANUAL_PAY_PAY_NOW_DEFAULT_PAYMENT_FAILURE_MODAL.TEXT_3, {
      retryTime: '4:30pm',
      retryDateString: bpMonthString,
    })

    modal.show({
      title: 'Couldn’t complete payment',
      cta: 'Close',
      render: () => (
        <Box>
          <SmallText>{text1}</SmallText>
          <Gap size="spacing_100" />
          <SmallText>{EMBED_MANUAL_PAY_PAY_NOW_DEFAULT_PAYMENT_FAILURE_MODAL.TEXT_2}</SmallText>
          <Gap size="spacing_100" />
          <SmallText>{text3}</SmallText>
          <Gap size="spacing_100" />
          <AppStore />
        </Box>
      ),
    })
  }

  const displayGenericPaymentFailureModal = (paymentAmountString: string) => {
    analytics.logEvent(Analytics.Events.EPISODIC_PAYMENT_FAILURE_MODAL, { failure: 'genericPaymentFailure' })

    const text1 = replaceContent(EMBED_MANUAL_PAY_PAY_NOW_GENERIC_PAYMENT_FAILURE_MODAL.TEXT_1, {
      paymentAmount: paymentAmountString,
    })
    const text3 = replaceContent(EMBED_MANUAL_PAY_PAY_NOW_GENERIC_PAYMENT_FAILURE_MODAL.TEXT_3, {
      retryTime: '4:30pm',
      retryDateString: bpMonthString,
    })

    modal.show({
      title: 'Couldn’t complete payment',
      cta: 'Close',
      render: () => (
        <Box>
          <SmallText>{text1}</SmallText>
          <Gap size="spacing_100" />
          <SmallText>{EMBED_MANUAL_PAY_PAY_NOW_GENERIC_PAYMENT_FAILURE_MODAL.TEXT_2}</SmallText>
          <Gap size="spacing_100" />
          <SmallText>{text3}</SmallText>
          <Gap size="spacing_100" />
          <AppStore />
        </Box>
      ),
    })
  }

  const displayUpdateCardPaymentFailureModal = (paymentAmountString: string) => {
    analytics.logEvent(Analytics.Events.EPISODIC_PAYMENT_FAILURE_MODAL, { failure: 'updateCardPaymentFailure' })

    const text1 = replaceContent(EMBED_MANUAL_PAY_PAY_NOW_UPDATE_CARD_MODAL.TEXT_1, {
      paymentAmount: paymentAmountString,
    })
    const text3 = replaceContent(EMBED_MANUAL_PAY_PAY_NOW_UPDATE_CARD_MODAL.TEXT_3, {
      retryTime: '4:30pm',
      retryDateString: bpMonthString,
    })

    modal.show({
      title: 'Couldn’t complete payment',
      cta: 'Close',
      render: () => (
        <Box>
          <SmallText>{text1}</SmallText>
          <Gap size="spacing_100" />
          <SmallText>{EMBED_MANUAL_PAY_PAY_NOW_UPDATE_CARD_MODAL.TEXT_2}</SmallText>
          <Gap size="spacing_100" />
          <SmallText>{text3}</SmallText>
          <Gap size="spacing_100" />
          <AppStore />
        </Box>
      ),
    })
  }

  const displayPartialPaymentsFailureModal = (amount: string, date: string, paymentType: string) => {
    const errorModalParagraphOne = replaceContent(
      EMBED_MANUAL_PAY_PAY_NOW_PARTIAL_PAYMENT_GENERIC_MODAL.PARAGRAPH_ONE,
      {
        amount,
      }
    )
    const errorModalParagraphTwo =
      paymentType === InAppPaymentType.OUTSTANDING_BALANCE
        ? EMBED_MANUAL_PAY_PAY_NOW_PARTIAL_PAYMENT_GENERIC_MODAL.PARAGRAPH_TWO_OUTSTANDING_BALANCE
        : replaceContent(EMBED_MANUAL_PAY_PAY_NOW_PARTIAL_PAYMENT_GENERIC_MODAL.PARAGRAPH_TWO_REPAYMENT, {
            date,
          })

    modal.show({
      title: EMBED_MANUAL_PAY_PAY_NOW_PARTIAL_PAYMENT_GENERIC_MODAL.TITLE,
      cta: EMBED_MANUAL_PAY_PAY_NOW_PARTIAL_PAYMENT_GENERIC_MODAL.CTA,
      onClose: () => {
        analytics.logEvent(Analytics.Events.PAY_NOW_FAILURE_MODAL_DONE_CLICKED, { modalType: 'generic', paymentType })
      },
      render: () => (
        <>
          <SmallText>{errorModalParagraphOne}</SmallText>
          <Gap size="spacing_100" />
          <SmallText>{errorModalParagraphTwo}</SmallText>
        </>
      ),
    })
  }

  const displayFundsSuccessCardFailureModal = (amount: string, date: string, paymentType: string) => {
    const errorModalParagraphOne = replaceContent(
      EMBED_MANUAL_PAY_PAY_NOW_PARTIAL_PAYMENT_CARD_FAILURE_MODAL.PARAGRAPH_ONE,
      {
        amount,
      }
    )
    const errorModalParagraphTwo =
      paymentType === InAppPaymentType.OUTSTANDING_BALANCE
        ? EMBED_MANUAL_PAY_PAY_NOW_PARTIAL_PAYMENT_CARD_FAILURE_MODAL.PARAGRAPH_TWO_OUTSTANDING_BALANCE
        : replaceContent(EMBED_MANUAL_PAY_PAY_NOW_PARTIAL_PAYMENT_CARD_FAILURE_MODAL.PARAGRAPH_TWO_REPAYMENT, {
            date,
          })

    modal.show({
      title: EMBED_MANUAL_PAY_PAY_NOW_PARTIAL_PAYMENT_CARD_FAILURE_MODAL.TITLE,
      cta: EMBED_MANUAL_PAY_PAY_NOW_PARTIAL_PAYMENT_CARD_FAILURE_MODAL.CTA,
      onClose: () => {
        analytics.logEvent(Analytics.Events.PAY_NOW_FAILURE_MODAL_DONE_CLICKED, {
          modalType: 'fundsSuccessCardFailure',
          paymentType,
        })
      },
      render: () => (
        <>
          <SmallText>{errorModalParagraphOne}</SmallText>
          <Gap size="spacing_100" />
          <SmallText>{errorModalParagraphTwo}</SmallText>
        </>
      ),
    })
  }

  const displayUnableToCompletePaymentModal = ({ amount }: { amount: string }) => {
    modal.show({
      title: EMBED_MANUAL_PAY_PAY_NOW_FAIL_MODAL.HEADER,
      cta: EMBED_MANUAL_PAY_PAY_NOW_FAIL_MODAL.CTA,
      onClose: () => {},
      render: () => <SmallText>{replaceContent(EMBED_MANUAL_PAY_PAY_NOW_FAIL_MODAL.BODY, { amount })}</SmallText>,
    })
  }

  return {
    displayInsufficientFundsModal,
    displayGenericPaymentFailureModal,
    displayDefaultPaymentFailureModal,
    displayUpdateCardPaymentFailureModal,
    displayPartialPaymentsFailureModal,
    displayFundsSuccessCardFailureModal,
    displayUnableToCompletePaymentModal,
  }
}
