import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import {
  FlexLinks,
  ONBOARDING_AGREEMENT_TYPES,
  ONBOARDING_SKIP_AUTOPILOT_AGREEMENT_TYPES,
  SetupAutopilotAction,
  SLC_ONBOARDING_AGREEMENT_TYPES,
  SLC_PRICING_ONBOARDING_AGREEMENT_TYPES,
} from '@genoa/domain'
import { AmplitudeFeatureFlag } from '@genoa/experiments'
import { useAcceptOffer, useAcceptOfferRelink, useAssignCustomerDda, useGetAgreements } from '@genoa/middle-end'
import { ErrorResponseData } from '@genoa/middle-end/lib/types'
import { MEMBERSHIP_FEE } from '@genoa/screen-content'
import { GeneralApiErrorCodes, useQueryError } from '@genoa/state-management'
import { Box, Flex } from '@chakra-ui/react'

import { useAuthState, useModal } from '../../../../contexts'
import {
  useAutopilotEligibility,
  useDefaultErrorModals,
  useEmbedAnalytics,
  useFlexAnywhereUser,
  useIsEmbed,
  useOnboardingAutopayToggleExperiment,
  usePaymentStatus,
  useReduxAction,
} from '../../../../hooks'
import { useGetOffer } from '../../../../hooks/flex2/risk'
import { useSecureLineOfCredit } from '../../../../hooks/secure-line-of-credit'
import { useAccount } from '../../../../hooks/use-account'
import { useAmplitudeFeatureFlag } from '../../../../hooks/use-amplitude-feature-flag'
import { setOfferAction } from '../../../../modules/flex2/offer'
import { usePricingOfferState } from '../../../../modules/flex2/use-state'
import { useAutopilotSetupMutation } from '../../../../modules/flexApi'
import {
  OnboardingStatus,
  setOnboardingStatus,
  useAnalytics,
  useEnhancedTracking,
  useFees,
  useHelpLinks,
  useIterable,
  useLogger,
} from '../../../../providers'
import { createSignUpCompletedEvent } from '../../../../providers/iterable/user-events'
import * as Routes from '../../../../routing/constants'
import { App } from '../../../../routing/constants'
import { InlineButton, SmallText } from '../../../components'
import { FullScreenSpinnerLoading } from '../../../components/SpinnerLoading/FullScreenSpinnerLoading'
import { ConfirmDateModalContainer } from '../payment-date-modal/V2ConfirmDateModalContainer'
import { useOffer } from '../use-offer'
import { AcceptanceOfferDetails } from './AcceptanceOfferDetails'
import { paymentStatusPaymentsToPaymentScheduleItems } from './payment-schedule/paymentStatusPaymentsToPaymentScheduleItems'

type V2AcceptanceOfferDetailsContainer = {
  isRelink?: boolean
}

const resolveAgreementTypes = (isEnabledForSLC: boolean, isUserAutopayEnabled: boolean, isPricingEnabled: boolean) => {
  if (isPricingEnabled) {
    return SLC_PRICING_ONBOARDING_AGREEMENT_TYPES
  }
  if (isEnabledForSLC) {
    return SLC_ONBOARDING_AGREEMENT_TYPES
  }
  if (isUserAutopayEnabled) {
    return ONBOARDING_AGREEMENT_TYPES
  }
  return ONBOARDING_SKIP_AUTOPILOT_AGREEMENT_TYPES
}

export const V2AcceptanceOfferDetailsContainer = ({ isRelink }: V2AcceptanceOfferDetailsContainer) => {
  const logger = useLogger('V2AcceptanceOfferDetailsContainer')
  const [loadingSetup, setLoadingSetup] = useState(true)
  const analytics = useAnalytics()
  const { trackUserSignUpCompleted } = useEnhancedTracking()
  const modal = useModal()
  const navigate = useNavigate()
  const ctaTitle = 'Accept'
  const helpLinks = useHelpLinks()
  const offer = useOffer()
  const setOfferState = useReduxAction(setOfferAction)
  const pricingOffer = usePricingOfferState()
  const { user, uid } = useAuthState()
  const {
    processingFeePercentage,
    isCardFeePassthroughEnabled,
    installments: payments,
    offerMonthlyRent: rentAmount,
    subscriptionFee: offerFee,
  } = useFees()
  const {
    payments: paymentStatusFull,
    isLoading: isPaymentStatusLoading,
    isError: paymentStatusError,
  } = usePaymentStatus()
  const { paymentStatusPayments, membershipFee } = paymentStatusPaymentsToPaymentScheduleItems(paymentStatusFull ?? [])
  const iterable = useIterable()
  const isOutOfNetworkUser = useFlexAnywhereUser()
  const { isUserAutopayEnabled, refetchEligibility } = useAutopilotEligibility()
  const [{ response: agreementsResponse }, getAgreements] = useGetAgreements()
  const { isEnabledForSLC, isLoadingSLC, isPricingEnabled } = useSecureLineOfCredit(true)
  const [dateModalOpen, setDateModalOpen] = useState(false)
  const [termsAccepted, setTermsAccepted] = useState(false)
  const { billerConnection, relinkingBillerConnection, connectionBillerAccountPublicId } = useAccount()
  const [{ loading: acceptLoading }, acceptOffer] = useAcceptOffer()
  const [{ loading: acceptRelinkLoading }, acceptOfferRelink] = useAcceptOfferRelink()
  const [{ loading: assignCustomerDdaLoading }, assignCustomerDda] = useAssignCustomerDda()
  const { getOffer } = useGetOffer()
  const isEmbed = useIsEmbed()
  const embedAnalytics = useEmbedAnalytics()
  const [autopilotSetup, { error: autoPilotSetupError }] = useAutopilotSetupMutation()
  const { displayTooManyTriesModal, displayDefaultErrorModal } = useDefaultErrorModals()
  const [isLoadingAutopilotSetup, setIsLoadingAutopilotSetup] = useState(false)
  const { isOnboardingAutopayToggleEnabled } = useOnboardingAutopayToggleExperiment()
  const { enabled: marchPaymentStatusRefactorEnabled } = useAmplitudeFeatureFlag(
    AmplitudeFeatureFlag.PaymentStatusRefactorOnboardingMarch
  )

  useQueryError(autoPilotSetupError, {
    onFlexApiError({ data: { error } }) {
      if (error.code === GeneralApiErrorCodes.RATE_LIMIT) {
        displayTooManyTriesModal()
        return true
      }
    },
    onAllErrors() {
      displayDefaultErrorModal()
    },
  })

  const acceptLoadingValue = !isRelink ? acceptLoading : acceptRelinkLoading
  const billerId = !isRelink
    ? billerConnection?.biller_account_public_id
    : relinkingBillerConnection?.biller_account_public_id

  useEffect(() => {
    const handleSetup = async () => {
      try {
        const autopilotEligibility = await refetchEligibility()
        const agreementTypes = resolveAgreementTypes(
          isEnabledForSLC,
          autopilotEligibility.isUserAutopayEnabled!,
          isPricingEnabled
        )

        analytics.logScreenView(Analytics.Screens.ACCEPTANCE_OFFER_DETAILS, {
          autopay_enabled: autopilotEligibility.isUserAutopayEnabled,
        })

        await getAgreements({
          customerId: uid!,
          agreementTypes,
          onlyFinancialPartnerSpecific: true,
        })
      } catch (error: any) {
        logger.error('Error in setup', error?.message)
        navigate(App.TRY_AGAIN)
      } finally {
        setLoadingSetup(false)
      }
    }
    if (!isLoadingSLC) {
      handleSetup()
    }
  }, [uid, isEnabledForSLC, isLoadingSLC, isPricingEnabled])

  useEffect(() => {
    const handleGetOffer = async () => {
      if (connectionBillerAccountPublicId) {
        await getOffer(connectionBillerAccountPublicId)
      }
    }
    handleGetOffer()
  }, [connectionBillerAccountPublicId])

  useEffect(() => {
    if (agreementsResponse) {
      const agreementsData = agreementsResponse.data
      if (agreementsResponse?.status !== 200 || agreementsData?.error) {
        const logArgs = {
          code: agreementsData?.error?.code,
          message: agreementsData?.error?.message,
          status: agreementsResponse?.status,
        }
        logger.error('Error fetching agreements', undefined, logArgs)
        navigate(App.TRY_AGAIN)
      }
    }
  }, [agreementsResponse])

  const initialDaySelected = useMemo(() => {
    if (payments.length) {
      return payments[payments.length - 1].day
    }

    return -1
  }, [payments])

  useEffect(() => {
    analytics.logEvent(Analytics.Events.ACCEPTANCE_OFFER_DETAILS_CTA_DISPLAYED, { isRelink })
  }, [])

  const renderGenericErrorAcceptOffer = () => {
    analytics.logEvent(Analytics.Events.ACCEPT_OFFER_FAIL)
    modal.show({
      title: 'Something went wrong',
      cta: 'Try again',
      render: () => <SmallText>We couldn&apos;t process your request.</SmallText>,
    })
  }
  const renderDataErrorAcceptOffer = (content: string) => {
    modal.show({
      title: 'Something went wrong',
      cta: 'Close',
      render: () => <SmallText>{content}</SmallText>,
    })
  }

  const renderRateLimitExceededAcceptOffer = () => {
    modal.show({
      title: 'Too many attempts',
      cta: 'Close',
      render: () => (
        <SmallText>
          You&apos;ve tried too many times, and we&apos;re running into some issues processing your request. Please try
          again later.
        </SmallText>
      ),
    })
  }

  const handleAcceptOffer = async (isOfferUserAutopayEnabled: boolean) => {
    if (!user?.uid || !billerId) return

    const handler = !isRelink ? acceptOffer : acceptOfferRelink
    const requestData = {
      offerId: offer.offer.offer_id,
      repayment_day: isEnabledForSLC ? undefined : offer.repayment_day,
      customerPublicId: user.uid,
      billerAccountPublicId: billerId,
    }
    analytics.logEvent(Analytics.Events.ACCEPT_OFFER_REQUEST, { isRelink })
    const acceptOfferResponse = await handler(requestData)

    if (acceptOfferResponse.status >= 200 && acceptOfferResponse.status < 300) {
      setOfferState({ initialized: true, offer: acceptOfferResponse.data })
      if (!isRelink) {
        await assignDda()
      }
      handleSuccess(isOfferUserAutopayEnabled)
    } else {
      analytics.logEvent(Analytics.Events.ACCEPT_OFFER_FAIL, { isRelink })

      const { status, data } = acceptOfferResponse

      if (status === 429) {
        logger.error('AcceptOffer', 'Rate limit exceeded')
        renderRateLimitExceededAcceptOffer()
      } else if (status === 422) {
        const errorResponse = data as unknown as ErrorResponseData
        logger.error('AcceptOffer', errorResponse?.reason)
        renderDataErrorAcceptOffer(errorResponse?.message)
      } else {
        logger.error('AcceptOffer', `status: ${status}`)
        renderGenericErrorAcceptOffer()
      }
    }
  }
  if (paymentStatusError) {
    navigate(App.TRY_AGAIN)
  }

  const handleLearnMore = useCallback(() => {
    analytics.logEvent(Analytics.Events.ACCEPTANCE_OFFER_DETAILS_LEARN_MORE_CLICKED, { isRelink })

    modal.show({
      title: '2nd payment to Flex',
      cta: 'Close',
      render: () => {
        return (
          <>
            <SmallText>You can change the date of this payment in Settings after signup is complete</SmallText>
          </>
        )
      },
    })
  }, [])

  const handleMembershipLearnMore = useCallback(() => {
    modal.show({
      title: MEMBERSHIP_FEE.HEADER,
      cta: MEMBERSHIP_FEE.CTA,
      render: () => {
        return (
          <Flex flexFlow="column">
            <Box data-testid="MembershipFeeBody">
              <SmallText>{MEMBERSHIP_FEE.BODY}</SmallText>
            </Box>

            <Box minH="24px" />
            <Flex justifyContent="center">
              <InlineButton
                onClick={() => {
                  helpLinks.open(FlexLinks.membershipFeeLearnMore)
                }}
              >
                <SmallText>
                  <b>{MEMBERSHIP_FEE.LEARN_MORE}</b>
                </SmallText>
              </InlineButton>
            </Flex>
          </Flex>
        )
      },
    })
  }, [helpLinks.open])

  const handleChangeAutopay = () => {
    analytics.logEvent(Analytics.Events.CHANGE_AUTOPAY_CLICKED)
    return navigate(Routes.Onboarding.AUTOPAY_SETUP, {
      state: { prefillSelection: true, nextRoute: Routes.Onboarding.OFFER_DETAILS },
    })
  }

  const handleChooseDate = useCallback(() => {
    analytics.logEvent(Analytics.Events.ACCEPTANCE_OFFER_DETAILS_CHOOSE_DATE_CLICKED, { isRelink })
    setDateModalOpen(true)
  }, [])

  const handleNext = async () => {
    let isOfferUserAutopayEnabled = isUserAutopayEnabled
    if (!isOnboardingAutopayToggleEnabled && !isEmbed && !isOfferUserAutopayEnabled) {
      try {
        setIsLoadingAutopilotSetup(true)
        await autopilotSetup({ customerId: uid!, status: SetupAutopilotAction.AUTOPAY_ON }).unwrap()
        const { isUserAutopayEnabled: isEnabled } = await refetchEligibility()
        isOfferUserAutopayEnabled = !!isEnabled
        setIsLoadingAutopilotSetup(false)
      } catch (error) {
        setIsLoadingAutopilotSetup(false)
        return displayDefaultErrorModal()
      }
    }

    if (isEmbed) {
      analytics.logEvent(Analytics.Events.EMBED_SCREEN_OFFER_DETAILS, {
        autopay: isOfferUserAutopayEnabled ? 'on' : 'off',
        embedFlow: embedAnalytics.embedFlow,
        pmcName: embedAnalytics.pmcName,
        propName: embedAnalytics.propertyName,
      })
    }

    analytics.logEvent(Analytics.Events.ACCEPTANCE_OFFER_DETAILS_CTA_CLICKED, {
      isRelink,
      autopay: isOfferUserAutopayEnabled ? 'on' : 'off',
    })
    if (offer.initialized && offer.offer && billerId) {
      try {
        await handleAcceptOffer(isOfferUserAutopayEnabled)
      } catch (error: any) {
        logger.error('AcceptOffer', `unhandled error info: ${error?.message}`)
        renderGenericErrorAcceptOffer()
      }
    }
  }

  const toggleAcceptTerms = useCallback(() => {
    const newValue = !termsAccepted
    setTermsAccepted(newValue)
    if (newValue) {
      analytics.logEvent(Analytics.Events.ACCEPTANCE_OFFER_DETAILS_ACCEPT_TERMS_LINK_CLICKED, { isRelink })
    }
  }, [termsAccepted])

  const handleSuccess = useCallback(
    (isOfferUserAutopayEnabled: boolean) => {
      analytics.logEvent(Analytics.Events.ACCEPT_OFFER_SUCCESS, {
        isRelink,
        autopay: isOfferUserAutopayEnabled ? 'on' : 'off',
      })
      iterable.addEvent(createSignUpCompletedEvent())
      trackUserSignUpCompleted()
      setOnboardingStatus(OnboardingStatus.Active)
      analytics.setUserProperty(
        Analytics.UserProperties.ONBOARDING_COMPLETION_TIMESTAMP,
        new Date().toISOString().slice(0, 19),
        true
      )
      if (isOutOfNetworkUser) {
        navigate(Routes.Onboarding.FLEX_ANYWHERE_HOW_FLEX_WORKS)
      } else if (isEmbed && !isOfferUserAutopayEnabled) {
        navigate(Routes.Embed.CONGRATS)
      } else {
        navigate(Routes.Onboarding.CONGRATS)
      }
    },
    [history, isEmbed, setOnboardingStatus]
  )

  const assignDda = async () => {
    if (billerConnection?.biller.system === 'portal' && user && !isRelink) {
      try {
        const assignCustomerDdaResponse = await assignCustomerDda({ customerId: user.uid })
        if (assignCustomerDdaResponse.status !== 201) {
          logger.error(
            'assignCustomerDda',
            `info: ${JSON.stringify({
              status: assignCustomerDdaResponse.status,
              data: assignCustomerDdaResponse.data,
            })}`
          )
        }
      } catch (error: any) {
        logger.error('assignCustomerDda - catch', `info: ${error?.message}}`)
      }
    }
  }

  const handleCloseDateModal = useCallback(() => {
    analytics.logEvent(Analytics.Events.CHOOSE_DATE_BACK_CLICKED)
    setDateModalOpen(false)
  }, [])

  if (loadingSetup || isLoadingSLC || isPaymentStatusLoading) {
    return <FullScreenSpinnerLoading />
  }

  const offerLoading = acceptLoadingValue || assignCustomerDdaLoading || isLoadingAutopilotSetup

  return (
    <>
      <AcceptanceOfferDetails
        onAcceptTerms={toggleAcceptTerms}
        onLearnMore={handleLearnMore}
        onMembershipLearnMore={handleMembershipLearnMore}
        onChooseDate={handleChooseDate}
        onChangeAutopay={handleChangeAutopay}
        onNext={handleNext}
        loading={isPaymentStatusLoading || offerLoading}
        termsAccepted={termsAccepted}
        rentAmount={rentAmount}
        //@ts-expect-error paymentStatusRefactor object does not include unused 'proportion' property
        payments={marchPaymentStatusRefactorEnabled ? paymentStatusPayments : payments}
        //@ts-expect-error membershipFee reads as possibly undefined, would occur during API loading or errro
        offerFee={marchPaymentStatusRefactorEnabled ? membershipFee : offerFee}
        ctaTitle={ctaTitle}
        showExtraFees={isCardFeePassthroughEnabled}
        processingFeePercentage={processingFeePercentage}
        membershipFeeReason={pricingOffer.pricing_offer.membership_fee_reason}
        agreements={agreementsResponse?.data.data?.agreements?.pending || []}
        isRelink={isRelink}
      />

      <ConfirmDateModalContainer
        initialDaySelected={initialDaySelected}
        onClose={handleCloseDateModal}
        isOpen={dateModalOpen}
      />
    </>
  )
}
