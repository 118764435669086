import React from 'react'
import { Analytics } from '@genoa/analytics'
import { CREDIT_BUILDER_WAITING_OFFER as content } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { CheckmarkCirclePurple, CreditBuilderWaiting as CreditBuilderWaitingImage } from '../../../../assets'
import { ExtraSmallText, Gap, Headline1, SmallText, Text } from '../../../../components'
import { CircleLoading } from '../../../../components/SpinnerLoading/CircleLoading'
import { BasePageLayout } from '../../../../layouts'

interface CreditBuilderWaitingOfferProps {
  isLoading: boolean
}

export const CreditBuilderWaitingOffer = (props: CreditBuilderWaitingOfferProps) => (
  <BasePageLayout analyticsScreenName={Analytics.Screens.ONBOARDING_WAITING_OFFER}>
    <Gap size="spacing_400" />

    <Flex align="center" justify="center">
      <CreditBuilderWaitingImage />
    </Flex>

    <Gap size="spacing_350" />

    <Container flex={1} flexDirection="column" px="lg" alignItems="center">
      <MainContent>
        <Headline1>{content.HEADER}</Headline1>

        <Gap size="spacing_150" />

        <HighlightContent>
          <HighlightWrapper>
            <CheckmarkCirclePurple />
            <HighlightTextWrapper>
              <SmallText fontWeight="bold">{content.HIGHLIGHT}</SmallText>
            </HighlightTextWrapper>
          </HighlightWrapper>
          <Gap size="spacing_50" />
          <HighlightTextWrapper>
            <ExtraSmallText color="dusk">{content.DISCLAIMER}</ExtraSmallText>
          </HighlightTextWrapper>
        </HighlightContent>

        <Gap size="spacing_150" />

        <SmallText>{content.BODY_WEB}</SmallText>

        <Gap size="spacing_400" />
      </MainContent>

      <Box>
        <CircleLoading />
      </Box>
    </Container>
  </BasePageLayout>
)

const Container = styled(Flex)`
  height: 100%;
`

const HighlightContent = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.cloud};
  padding: ${(props) => props.theme.fixedSizes.spacing_100};
  border-radius: ${(props) => props.theme.radii.lg};
`

const HighlightWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-self: center;
`

const HighlightTextWrapper = styled(Box)`
  display: flex;
  padding-left: ${(props) => props.theme.fixedSizes.spacing_75};
  align-items: flex-start;
  text-align: left;
`

const MainContent = styled(Box)`
  justify-content: center;
  align-items: center;
  text-align: center;
`
