import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'

import * as Routes from '../../../../../routing/constants'
import { DirectIntegrationConfirmAddressContainer } from './direct-integration-confirm-address/DirectIntegrationConfirmAddressContainer'

type RelinkDirectIntegrationConfirmAddressContainerProps = {}

export const RelinkDirectIntegrationConfirmAddressContainer = (
  props: RelinkDirectIntegrationConfirmAddressContainerProps
) => {
  const navigate = useNavigate()

  return (
    <DirectIntegrationConfirmAddressContainer
      {...props}
      analyticsScreenName={Analytics.Screens.EMBED_RELINK_CONFIRM_ADDRESS}
      analyticsEventCTAClicked={Analytics.Events.EMBED_RELINK_CONFIRM_ADDRESS_CLICKED}
      analyticsEventAddressNotRightClicked={Analytics.Events.EMBED_RELINK_ADDRESS_NOT_RIGHT_CLICKED}
      isRelink
      onNext={() => navigate(Routes.Embed.RELINK_RENT_AMOUNT)}
      onAddressNotRight={() => {
        navigate(Routes.Embed.PROPERTY_MISMATCH)
      }}
    />
  )
}
