import { ordinalSuffixFor } from '@genoa/utils'
import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { Nudge28, Nudge29, Nudge30, Nudge31 } from '../../../../assets'
import { Gap, LinkButton, Modal } from '../../../../components'
import { PrimaryButton } from '../../../../components/Buttons/PrimaryButton'
import { ModalActions } from '../../../../components/Modal/Modal'
import { SmallText } from '../../../../components/Typography/Typography'

interface PaymentsCloseTogetherModalProps {
  visible: boolean
  paymentDate: number
  onClickPrimary: () => void
  onClickSecondary: () => void
}

const Nudge = ({ paymentDate }: { paymentDate: number }) => {
  if (paymentDate === 28)
    return (
      <>
        <NudgeBackground>
          <Nudge28 width="100%" />
        </NudgeBackground>
        <Gap size="spacing_100" />
      </>
    )
  if (paymentDate === 29)
    return (
      <>
        <NudgeBackground>
          <Nudge29 width="100%" />
        </NudgeBackground>
        <Gap size="spacing_100" />
      </>
    )
  if (paymentDate === 30)
    return (
      <>
        <NudgeBackground>
          <Nudge30 width="100%" />
        </NudgeBackground>
        <Gap size="spacing_100" />
      </>
    )
  if (paymentDate === 31)
    return (
      <>
        <NudgeBackground>
          <Nudge31 width="100%" />
        </NudgeBackground>
        <Gap size="spacing_100" />
      </>
    )
  return null
}

const NudgeBackground = styled.div`
  background-color: ${({ theme }) => theme.colors.wildflower};
  border-radius: 8px;
`

export const PaymentsCloseTogetherModal = ({
  visible,
  paymentDate,
  onClickPrimary,
  onClickSecondary,
}: PaymentsCloseTogetherModalProps) => {
  return (
    <Modal visible={visible} canClose={false} title="Your payments are close together" onClose={() => {}}>
      <Nudge paymentDate={paymentDate} />
      <SmallText>
        This schedule may strain your cash flow and make it difficult to make next month's rent. We recommend scheduling
        your 2nd payment closer to the 15th.
      </SmallText>
      <ModalActions>
        <Box flex={1} flexDirection="column">
          <Box>
            <Box>
              <LinkButton onClick={onClickSecondary}>Continue with the {ordinalSuffixFor(paymentDate)}</LinkButton>
              <Box minH="12px" />
            </Box>
          </Box>
          <PrimaryButton onClick={onClickPrimary}>Choose another day</PrimaryButton>
        </Box>
      </ModalActions>
    </Modal>
  )
}
