import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { P2P_WAITLIST_CONTENT } from '@genoa/screen-content'
import { Box, Stack } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useAnalytics } from '../../../../providers'
import * as Routes from '../../../../routing/constants'
import { ExtraSmallText, Gap, Headline1, Headline2, PrimaryButton } from '../../../components'
import { RadioGroup } from '../../../components/Form/RadioGroup'
import { BasePageLayout } from '../../../layouts'

const { ONBOARDING_CTA, ERROR, PAY_FIELD_LABEL, PAY_FIELD_NAME, PAY_OPTIONS, PLACEHOLDER } = P2P_WAITLIST_CONTENT

const FlexAnywhereHowYouCurrentlyPayRent: React.FC = () => {
  const schema = yup.object().shape({
    [PAY_FIELD_NAME]: yup.string().required(),
  })
  const { colors } = useTheme()
  const navigate = useNavigate()

  const formProps = useForm({ resolver: yupResolver(schema) })
  const {
    formState: { isValid, isSubmitted, isSubmitting },
    handleSubmit,
  } = formProps
  const analytics = useAnalytics()
  const [selectedOption, setSelectedOption] = useState('')

  const onSubmit = handleSubmit((data) => {
    analytics.logEvent(Analytics.Events.HOW_YOU_CURRENTLY_PAY_RENT_CTA_CLICKED, data)
    // Navigate to rent portal if selected "rent portal or property website"
    if (selectedOption === 'rent_portal_or_property_website') {
      navigate(Routes.Onboarding.RENT_PORTAL_SELECTION)
    } else {
      // Navigate to P2P waitlist
      navigate(Routes.Onboarding.P2P_WAITLIST)
    }
  })

  return (
    <BasePageLayout analyticsScreenName={Analytics.Screens.FLEX_ANYWHERE_HOW_YOU_CURRENTLY_PAY_RENT}>
      <Box minH="30px" />

      <Headline2>{PAY_FIELD_LABEL}</Headline2>
      <Box minH="24px" />
      <FormProvider {...formProps}>
        <form onSubmit={onSubmit}>
          <Stack spacing={8}>
            <RadioGroup
              name={PAY_FIELD_NAME}
              options={PAY_OPTIONS}
              title={PAY_FIELD_LABEL}
              showTitle={false}
              allowCustomInput
              customInputConfig={{ value: 'other', maxLength: 500, placeholder: PLACEHOLDER }}
              onOptionSelect={setSelectedOption}
            />
            {!isValid && isSubmitted && (
              <ExtraSmallText color={colors.red} textAlign="center">
                {ERROR}
              </ExtraSmallText>
            )}
            <Box px="sm">
              <PrimaryButton processing={isSubmitting} onClick={onSubmit}>
                {ONBOARDING_CTA}
              </PrimaryButton>
            </Box>
          </Stack>
        </form>
      </FormProvider>
    </BasePageLayout>
  )
}

export default FlexAnywhereHowYouCurrentlyPayRent
