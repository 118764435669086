import { useLocation } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'

import { useAnalytics } from '../../../../../../providers'
import { ManualPayFinish } from './ManualPayFinish'

export const ManualPayFinishContainer = () => {
  const analytics = useAnalytics()
  const {
    state: { downPaymentAmount },
  } = useLocation()

  const handleContinue = () => {
    analytics.logEvent(Analytics.Events.MANUAL_PAY_FINISH_CTA_CLICK)
    window.parent.postMessage(
      JSON.stringify({ embed: 'embedMessage', message: 'Rent has been paid.', type: 'embed-success' }),
      '*'
    )
  }

  return <ManualPayFinish downPaymentAmount={downPaymentAmount} handleContinue={handleContinue} />
}
