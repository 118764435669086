import { FormProvider, useForm } from 'react-hook-form'
import { Analytics } from '@genoa/analytics'
import { P2P_WAITLIST_CONTENT } from '@genoa/screen-content'
import { Box, Stack } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useAnalytics } from '../../../../providers'
import { Megaphone } from '../../../assets'
import { Field, Headline2, PrimaryButton, SmallText } from '../../../components'
import { RadioGroup } from '../../../components/Form/RadioGroup'
import { BasePageLayout } from '../../../layouts'

const {
  BODY,
  PAYMENT_CHANGE_FIELD_NAME,
  PAYMENT_CHANGE_FIELD_LABEL,
  PAYMENT_CHANGE_OPTIONS,
  CTA,
  ERROR,
  RENT_DUE_FIELD_NAME,
  RENT_DUE_FIELD_LABEL,
  RENT_DUE_OPTIONS,
  TITLE,
  SUCCESS_BODY,
  SUCCESS_HEADING,
  LANDLORD_PROPERTY_MANAGER_FIELD_LABEL,
  LANDLORD_PROPERTY_MANAGER_FIELD_NAME,
} = P2P_WAITLIST_CONTENT

const schema = yup.object().shape({
  [RENT_DUE_FIELD_NAME]: yup.string().required(),
  [PAYMENT_CHANGE_FIELD_NAME]: yup.string().required(),
  [LANDLORD_PROPERTY_MANAGER_FIELD_NAME]: yup.string().required(),
})

export function P2PWaitlist() {
  const { colors } = useTheme()
  const useFormProps = useForm({ resolver: yupResolver(schema) })

  const {
    formState: { isValid, isSubmitted, isSubmitSuccessful, isSubmitting },
    handleSubmit,
    control,
  } = useFormProps
  const analytics = useAnalytics()

  const onSubmit = handleSubmit((data) => {
    analytics.logEvent(Analytics.Events.P2P_WAITLIST_CTA_CLICKED, data)
  })

  return (
    <BasePageLayout analyticsScreenName={Analytics.Screens.ONBOARDING_P2P_WAITLIST}>
      <Stack spacing={5} alignItems="center" mt="spacing_50">
        {isSubmitSuccessful ? (
          <Stack alignItems="center" mt="8">
            <Megaphone />
            <Headline2 textAlign="center">{SUCCESS_HEADING}</Headline2>
            <SmallText textAlign="center">{SUCCESS_BODY}</SmallText>
          </Stack>
        ) : (
          <>
            <Megaphone />
            <Headline2 textAlign="center">{TITLE}</Headline2>
            <SmallText textAlign="center">{BODY}</SmallText>
            <Box w="full" borderRadius="spacing_50" p="spacing_25">
              <FormProvider {...useFormProps}>
                <form onSubmit={onSubmit}>
                  <Stack spacing={8}>
                    <RadioGroup name={RENT_DUE_FIELD_NAME} options={RENT_DUE_OPTIONS} title={RENT_DUE_FIELD_LABEL} />
                    <RadioGroup
                      name={PAYMENT_CHANGE_FIELD_NAME}
                      title={PAYMENT_CHANGE_FIELD_LABEL}
                      options={PAYMENT_CHANGE_OPTIONS}
                    />
                    <Box mb="spacing_0">
                      <Field
                        label={<SmallText fontWeight="bold">{LANDLORD_PROPERTY_MANAGER_FIELD_LABEL}</SmallText>}
                        name={LANDLORD_PROPERTY_MANAGER_FIELD_NAME}
                        control={control}
                        maxLength={200}
                      />
                    </Box>
                    {!isValid && isSubmitted && (
                      <SmallText textAlign="center" color={colors.red}>
                        {ERROR}
                      </SmallText>
                    )}
                    <PrimaryButton processing={isSubmitting} type="submit">
                      {CTA}
                    </PrimaryButton>
                  </Stack>
                </form>
              </FormProvider>
            </Box>
          </>
        )}
      </Stack>
    </BasePageLayout>
  )
}
