import React from 'react'
import { Analytics } from '@genoa/analytics'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { SpinnerLoading } from '../../../../../views/components/SpinnerLoading/SpinnerLoading'
import { HeroSocks as HangTightImage } from '../../../../assets'
import { Gap, Headline1, SmallText, Text } from '../../../../components'
import { BasePageLayout } from '../../../../layouts'

export const WaitingOffer = () => (
  <BasePageLayout analyticsScreenName={Analytics.Screens.ONBOARDING_WAITING_OFFER}>
    <Gap size="spacing_400" />

    <Flex align="center" justify="center">
      <HangTightImage />
    </Flex>

    <Gap size="spacing_350" />

    <Container flex={1} flexDirection="column" px="lg" alignItems="center">
      <Headline1>Hang tight</Headline1>

      <Gap size="spacing_150" />

      <Text>Please keep this page open while we process your application</Text>

      <Gap size="spacing_150" />

      <SmallText fontWeight="bold">This may take a few minutes</SmallText>

      <Gap size="spacing_400" />

      <Box>
        <SpinnerLoading />
      </Box>
    </Container>
  </BasePageLayout>
)

const Container = styled(Flex)`
  height: 100%;
`
