import React from 'react'
import { Button, ButtonProps, Flex } from '@chakra-ui/react'
import { transparentize } from '@chakra-ui/theme-tools'

type PrimaryButtonProps = {
  children: React.ReactNode
  disabled?: boolean
  processing?: boolean
  onClick?: () => unknown
  testID?: string
  background?: string
  foreground?: string
  type?: ButtonProps['type']
  style?: object
}

export const PrimaryButton = (props: PrimaryButtonProps) => {
  return (
    <Flex w="100%" flexDirection="row">
      <Button
        {...props.style}
        type={props.type}
        data-testid={props.testID}
        variant="primary"
        flexGrow={1}
        w="100%"
        _hover={{ bg: transparentize(props.background || 'brand.800', 0.75) }}
        _focusVisible={{
          boxShadow: '0px 0px 0px 2px white',
          outline: '2px solid #1C53BA',
          outlineOffset: '2px',
        }}
        padding="13px 32px 19px 32px"
        isLoading={props.processing}
        isDisabled={props.disabled}
        onClick={props.onClick}
        color={props.foreground}
        backgroundColor={props.background}
      >
        {props.children}
      </Button>
    </Flex>
  )
}
