import { Control, FormState, UseFormReturn } from 'react-hook-form'
import { Analytics } from '@genoa/analytics'
import { EMBED_RELINK_VERIFY_IDENTITY } from '@genoa/screen-content'
import { Box, Flex, useTheme } from '@chakra-ui/react'

import { Field, Headline1, PrimaryButton, SmallText, TextInput } from '../../../../../components'
import { BasePageLayout } from '../../../../../layouts'

export type VerifyIdentityState = {
  dob: string
  ssn: string
}

type VerifyIdentityProps = {
  analyticsScreenName: Analytics.Screens
  onDateChange: (date: string) => void
  onSetSSN: (ssn: string) => void
  onClickContinue: () => void
  ssnPlaceholder: string
  isLoading: boolean
  control: Control<VerifyIdentityState>
  formErrors: FormState<VerifyIdentityState>['errors']
  getValues: UseFormReturn<VerifyIdentityState>['getValues']
}

export const VerifyIdentity = (props: VerifyIdentityProps) => {
  const theme = useTheme()

  const ssn = props.getValues('ssn')
  const date = props.getValues('dob')
  const baseDisabled = !ssn || !date || props.isLoading

  return (
    <>
      <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
        <Flex direction="column">
          <Box minH={theme.fixedSizes.md} />

          <Headline1>{EMBED_RELINK_VERIFY_IDENTITY.HEADER}</Headline1>

          <Box minH={theme.fixedSizes.lg} />

          <SmallText>{EMBED_RELINK_VERIFY_IDENTITY.BODY}</SmallText>

          <Box minH={theme.fixedSizes.lg} />

          <Box>
            <Field
              control={props.control}
              defaultValue={date}
              error={props.formErrors.dob}
              input={TextInput}
              label="Date of birth"
              name="dob"
              onChange={props.onDateChange}
              placeholder="MM / DD / YYYY"
              preventChangeValue
              testID="ConfirmAccountDateOfBirthInput"
            />
          </Box>

          <Box minH={theme.fixedSizes.md} />

          <Box>
            <Field
              control={props.control}
              error={props.formErrors.ssn}
              input={TextInput}
              label="Social security number"
              name="ssn"
              onChange={props.onSetSSN}
              placeholder={props.ssnPlaceholder}
              preventChangeValue
              testID="ConfirmAccountSsnInput"
            />
          </Box>
          <Box minH={theme.fixedSizes.xl} />

          <PrimaryButton
            disabled={baseDisabled}
            processing={props.isLoading}
            onClick={props.onClickContinue}
            testID="ConfirmAccountContinueButton"
          >
            {EMBED_RELINK_VERIFY_IDENTITY.CTA}
          </PrimaryButton>
        </Flex>
      </BasePageLayout>
    </>
  )
}
