import React from 'react'
import { CREDIT_BUILDER_CUSTOMIZE_SCHEDULE_CONTENT as content } from '@genoa/screen-content'
import { PaymentInformation } from '@genoa/utils'
import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { WhenDesktop, WhenTablet } from '../../../../../theme/media'
import { ExtraSmallText, Frame, Gap, Headline2, PrimaryButton, SmallText, Text } from '../../../../components'
import { BasePageLayout } from '../../../../layouts'
import { PaymentListContainer } from './PaymentListContainer'

interface CreditBuilderCustomizeSchedule {
  onSelectPaymentDay: (secondPaymentDay: number) => void
  paymentDays: number[]
  onClickConfirmButton: () => void
  daySelected?: number | undefined
  loadingScheduleOptions?: boolean
  loading?: boolean
  showApprovedModal: boolean
  displayError: boolean
  formatter: (day: number) => string
  rentAmountFormatted: string
}

export const CreditBuilderCustomizeSchedule = (props: CreditBuilderCustomizeSchedule) => {
  return (
    <BasePageLayout>
      <Gap size="spacing_200" />

      <Headline2>{content.HEADER}</Headline2>
      <Gap size="spacing_100" />

      <SmallText>{content.SUB_HEADER}</SmallText>
      <Gap size="spacing_200" />
      {!props.loadingScheduleOptions && props.paymentDays.length > 0 ? (
        <SecondPaymentListWrapper
          style={{
            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: 1,
            },
            shadowOpacity: 0.25,
            shadowRadius: 2,
            elevation: 3,
          }}
        >
          <>
            <PaymentListContainer
              paymentTitle="Choose date"
              onSelectPaymentDay={props.onSelectPaymentDay}
              paymentDays={props.paymentDays}
              daySelected={props.daySelected}
              forceTopRightText
              topRightText={props.rentAmountFormatted}
            />
            <Gap size="spacing_500" />
            {props.daySelected && props.daySelected > 1 && props.daySelected <= 3 && (
              <FirstPaymentDisclaimerFrame>
                <ExtraSmallText>{content.NUDGE}</ExtraSmallText>
              </FirstPaymentDisclaimerFrame>
            )}
          </>
        </SecondPaymentListWrapper>
      ) : null}

      <WhenDesktop>
        <Gap size="spacing_100" />
      </WhenDesktop>
      <WhenTablet>
        <Gap size="spacing_50" />
      </WhenTablet>

      <Gap size="spacing_100" />
      <PrimaryButton
        testID="CreditBuilderCustomizeScheduleConfirmButton"
        disabled={props.loading || !props.daySelected}
        processing={props.loading}
        onClick={props.onClickConfirmButton}
      >
        {content?.CTA}
      </PrimaryButton>
    </BasePageLayout>
  )
}

const DateDropdownContainer = styled(Box)`
  width: 100%;
`
const SecondPaymentListWrapper = styled(Box)`
  border-radius: ${(props) => props.theme.radii?.xl};
  flex-direction: column;
  align-self: center
  width: 99%;
  background-color: ${(props) => props.theme.colors.white};
  padding: ${(props) => props.theme.fixedSizes?.md};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);

`
const FirstPaymentDisclaimerFrame = styled(Frame)`
  margin-horizontal: ${(props) => props.theme.fixedSizes?.spacing_50};
  background-color: ${(props) => props.theme.colors.cloud};
  margin-vertical: ${(props) => props.theme.fixedSizes?.spacing_100};
`
