import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'

import * as Routes from '../../../../../routing/constants'
import { InitiateOfferRentAmountContainer } from '../../../common/rent-amount/InitiateOfferRentAmountContainer'

export const RelinkRentAmountContainer = () => {
  const navigate = useNavigate()

  const handleOnNext = () => {
    navigate(Routes.Embed.RELINK_VERIFY_IDENTITY)
  }

  return (
    <InitiateOfferRentAmountContainer
      analyticsScreenName={Analytics.Screens.EMBED_RELINK_RENT_AMOUNT}
      analyticsEventCTAClicked={Analytics.Events.RENT_AMOUNT_CTA_CLICKED}
      analyticsEventCTAError={Analytics.Events.RENT_AMOUNT_CTA_ERROR}
      onNext={handleOnNext}
    />
  )
}
