import React from 'react'
import { Analytics } from '@genoa/analytics'
import { WAITING_OFFER } from '@genoa/screen-content'
import { Flex } from '@chakra-ui/react'

import { HeroSocks } from '../../../assets'
import { Gap, Headline1, SmallText } from '../../../components'
import { BasePageLayout } from '../../../layouts'

interface DocumentVerificationProps {
  analyticsScreenName: Analytics.Screens
}

export const DocumentVerificationProcessing = (props: DocumentVerificationProps) => {
  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Flex alignItems="center" direction="column">
        <Gap size="spacing_200" />

        <HeroSocks />

        <Gap size="spacing_200" />

        <Headline1>{WAITING_OFFER.HEADER}</Headline1>

        <Gap size="spacing_200" />

        <SmallText textAlign="center">{WAITING_OFFER.BODY}</SmallText>

        <Gap size="spacing_100" />
        <SmallText textAlign="center">{WAITING_OFFER.BODY_BOLD}</SmallText>

        <Gap size="spacing_200" />

        <div id="websdk"></div>
      </Flex>
    </BasePageLayout>
  )
}
