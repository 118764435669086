import { useCallback } from 'react'
import { Analytics } from '@genoa/analytics'
import { BillerPropertyPortalDetails, BillerPropertyUnit, BillerResidentPrefill, IntegrationType } from '@genoa/domain'
import {
  GetBillerPropertyPortalDetailsRequest,
  useGetBillerPropertyDetails,
  useGetBillerPropertyPortalDetails,
} from '@genoa/middle-end'
import {
  GetPropertyUnitArgs,
  setBillerPropertyDetailsAction,
  setBillerPropertyPortalDetailsAction,
  setBillerUnitAction,
  setPrefillsIsActiveAction,
  setPrefillsIsLoadingAction,
} from '@genoa/state-management'

import { useAuthState } from '../../contexts'
import { RootState } from '../../modules'
import { useLazyGetPropertyUnitQuery, useLazyGetResidentPrefillQuery } from '../../modules/flexApi'
import { useAnalytics, useLogger } from '../../providers'
import { useSecureLineOfCredit } from '../secure-line-of-credit'
import { useReduxAction } from '../use-redux-action'
import { useReduxSelector } from '../use-redux-selector'
import { assertBillerPropertyDetails, BillerPropertyDetailsWithLocation } from './biller'
import { useHandleIterableDetails } from './use-handle-iterable-details'
import { useResidentDataPrefillsExperiment } from './use-prefills-experiment'

export interface PrefillRedirects {
  readonly navigateToAddressSelection: () => void
  readonly navigateToUnitSelection: () => void
  readonly navigateToConfirmAddress: () => void
  readonly navigateToRentPortalSignIn: () => void
}

export interface UsePrefills {
  readonly loadingPropertyPrefills: boolean
  readonly prefillIsActive: boolean
  readonly handlePrefill: (navigators: PrefillRedirects) => Promise<void>
  readonly resetPrefills: () => void
}

export const usePrefills = (): UsePrefills => {
  const analytics = useAnalytics()
  const { isAnonymous, uid } = useAuthState()
  const handleIterableDetails = useHandleIterableDetails()
  const logger = useLogger('PrefillsProvider')
  const { isPrefillsEnabled } = useResidentDataPrefillsExperiment()
  const setPrefillsIsActive = useReduxAction(setPrefillsIsActiveAction)
  const setPrefillsIsLoading = useReduxAction(setPrefillsIsLoadingAction)
  const isActive = useReduxSelector((state: RootState) => state.prefills.isActive)
  const isLoading = useReduxSelector((state: RootState) => state.prefills.isLoading)

  const setBillerPropertyDetails = useReduxAction(setBillerPropertyDetailsAction)
  const setBillerPropertyPortalDetails = useReduxAction(setBillerPropertyPortalDetailsAction)
  const setBillerUnit = useReduxAction(setBillerUnitAction)

  const [, getBillerPropertyDetails] = useGetBillerPropertyDetails()
  const [, getBillerPropertyPortalDetails] = useGetBillerPropertyPortalDetails()

  const [getPropertyUnit] = useLazyGetPropertyUnitQuery()
  const [getResidentPrefill] = useLazyGetResidentPrefillQuery()
  const { isFlagEnabledForCreditBuilder } = useSecureLineOfCredit()

  const handleRedirectNoMatch = (navigate: () => void) => {
    setPrefillsIsActive(false)
    setPrefillsIsLoading(false)
    navigate()
  }

  const handleRedirectMatch = (navigate: () => void) => {
    setPrefillsIsActive(true)
    navigate()
  }

  const tryMatchResident = async (customerId: string): Promise<BillerResidentPrefill | undefined> => {
    try {
      analytics.logEvent(Analytics.Events.PREFILLS_GET_MATCH_RESIDENT)

      const response = await getResidentPrefill({ customerId }).unwrap()
      if (!response.ok) {
        throw new Error(response.error?.message)
      }

      analytics.logEvent(Analytics.Events.PREFILLS_GET_MATCH_RESIDENT_SUCC)
      return response.data
    } catch (error: any) {
      analytics.logEvent(Analytics.Events.PREFILLS_GET_MATCH_RESIDENT_ERROR)
      logger.warn('tryMatchResident', error?.message)
      return undefined
    }
  }

  const tryMatchBillerPropertyDetails = async (
    options: GetBillerPropertyPortalDetailsRequest
  ): Promise<BillerPropertyDetailsWithLocation | undefined> => {
    try {
      analytics.logEvent(Analytics.Events.PREFILLS_BILLER_ADDRESS_START, options)

      const billerResponse = await getBillerPropertyDetails(options)
      const propertyDetails = assertBillerPropertyDetails(billerResponse.data)

      analytics.logEvent(Analytics.Events.PREFILLS_BILLER_ADDRESS_SUCC, options)
      return propertyDetails
    } catch (error: any) {
      analytics.logEvent(Analytics.Events.PREFILLS_BILLER_ADDRESS_FAIL, options)
      logger.error('tryGetBillerAddressDetails', error?.message)
      return undefined
    }
  }

  const tryMatchBillerPropertyPortalDetails = async (
    options: GetBillerPropertyPortalDetailsRequest
  ): Promise<BillerPropertyPortalDetails | undefined> => {
    try {
      analytics.logEvent(Analytics.Events.PREFILLS_MATCH_PROPERTY_START, options)

      const response = await getBillerPropertyPortalDetails(options)
      if (response.status === 404) {
        throw new Error('Failed to find biller portal details')
      }

      analytics.logEvent(Analytics.Events.PREFILLS_MATCH_PROPERTY_SUCC, options)
      return response.data
    } catch (error: any) {
      analytics.logEvent(Analytics.Events.PREFILLS_MATCH_PROPERTY_FAIL, options)
      logger.error('tryMatchBillerPropertyPortalDetails', error?.message)
      return undefined
    }
  }

  const tryMatchBillerPropertyUnit = async (options: GetPropertyUnitArgs): Promise<BillerPropertyUnit | undefined> => {
    try {
      analytics.logEvent(Analytics.Events.PREFILLS_MATCH_UNIT_START, options)

      const response = await getPropertyUnit(options).unwrap()
      if (!response.ok) {
        throw new Error(response.error?.message)
      }

      analytics.logEvent(Analytics.Events.PREFILLS_MATCH_UNIT_SUCC, options)
      return response.data
    } catch (error: any) {
      analytics.logEvent(Analytics.Events.PREFILLS_MATCH_UNIT_FAIL, { ...options, error: error?.message })
      logger.error('tryMatchBillerPropertyUnit', error?.message)
      return undefined
    }
  }

  const handleMatchedProperty = (propertyDetails: BillerPropertyDetailsWithLocation) => {
    analytics.logEvent(Analytics.Events.PREFILLS_PROPERTY_SUCC)
    handleIterableDetails(propertyDetails)

    setBillerPropertyDetails(propertyDetails)
  }

  const handleMatchedPropertyAndUnit = (
    propertyDetails: BillerPropertyDetailsWithLocation,
    unit: BillerPropertyUnit
  ) => {
    analytics.logEvent(Analytics.Events.PREFILLS_PROPERTY_SUCC)
    handleIterableDetails(propertyDetails)
    setBillerPropertyDetails(propertyDetails)
    setBillerUnit(unit)
  }

  const handleMatchedPortal = (
    propertyDetails: BillerPropertyDetailsWithLocation,
    portal: BillerPropertyPortalDetails
  ) => {
    analytics.logEvent(Analytics.Events.PREFILLS_PROPERTY_SUCC)
    handleIterableDetails(propertyDetails)

    setBillerPropertyDetails(propertyDetails)
    setBillerPropertyPortalDetails(portal)
  }

  const handlePrefillInternal = async (customerId: string, navigators: PrefillRedirects) => {
    const redirectNoMatch = () => handleRedirectNoMatch(navigators.navigateToAddressSelection)

    const fields = await tryMatchResident(customerId)
    if (!fields) {
      return redirectNoMatch()
    }

    const propertyId = fields.property_id.toFixed()
    const unitId = fields.unit

    const propertyDetails = await tryMatchBillerPropertyDetails({ propertyId })

    if (!propertyDetails) {
      return redirectNoMatch()
    }

    if (isFlagEnabledForCreditBuilder && propertyDetails.integration_type !== IntegrationType.DIRECT_INTEGRATION) {
      return redirectNoMatch()
    }

    if (propertyDetails.integration_type === IntegrationType.PORTAL) {
      const portalDetails = await tryMatchBillerPropertyPortalDetails({ propertyId })

      if (!portalDetails) {
        return redirectNoMatch()
      }

      handleMatchedPortal(propertyDetails, portalDetails)
      return handleRedirectMatch(navigators.navigateToRentPortalSignIn)
    }

    if (propertyDetails.integration_type === IntegrationType.EMBED) {
      if (unitId) {
        const unit = {
          label: unitId,
          unit: unitId,
          address_line_1: propertyDetails.location.address_line_1,
          address_line_2: propertyDetails.location.address_line_2,
          city: propertyDetails.location.city,
          state: propertyDetails.location.state,
          postal_code: propertyDetails.location.postal_code,
        }
        handleMatchedPropertyAndUnit(propertyDetails, unit)
        return handleRedirectMatch(navigators.navigateToConfirmAddress)
      }
      handleMatchedProperty(propertyDetails)
      return handleRedirectMatch(navigators.navigateToUnitSelection)
    }

    if (
      propertyDetails.integration_type === IntegrationType.REALPAGE ||
      propertyDetails.integration_type === IntegrationType.YARDI ||
      propertyDetails.integration_type === IntegrationType.DIRECT_INTEGRATION
    ) {
      if (unitId) {
        const unit = await tryMatchBillerPropertyUnit({
          propertyId,
          unitId,
        })

        if (unit) {
          handleMatchedPropertyAndUnit(propertyDetails, unit)
          return handleRedirectMatch(navigators.navigateToConfirmAddress)
        }
      }

      handleMatchedProperty(propertyDetails)
      return handleRedirectMatch(navigators.navigateToUnitSelection)
    }

    return redirectNoMatch()
  }

  const handlePrefill = useCallback(
    async (navigators: PrefillRedirects): Promise<void> => {
      if (!isPrefillsEnabled) {
        return handleRedirectNoMatch(navigators.navigateToAddressSelection)
      }

      if (isAnonymous || !uid) {
        return handleRedirectNoMatch(navigators.navigateToAddressSelection)
      }

      setPrefillsIsLoading(true)
      await handlePrefillInternal(uid, navigators)
      setPrefillsIsLoading(false)
    },
    [uid, isLoading, isPrefillsEnabled, isAnonymous]
  )

  const resetPrefills = () => {
    analytics.logEvent(Analytics.Events.PREFILLS_RESET)
    setPrefillsIsActive(false)
  }

  return {
    loadingPropertyPrefills: isLoading,
    prefillIsActive: isActive,
    handlePrefill,
    resetPrefills,
  }
}
